@import '~@ng-select/ng-select/themes/default.theme.css';

.form-group {
  display: grid;
  row-gap: 1rem;
  margin-bottom: 1.2rem;

  &--nowrap {
    text-wrap: nowrap;
  }

  &--disable {
    pointer-events: none;
    opacity: .85;
  }
}

.input-icon {
  display: flex;
  column-gap: .8rem;
  align-items: center;
  border-radius: 1rem;
  border: 1px solid rgb(var(--light-gray));
  padding: 0 1rem;



  &--text {
    &:focus-within {
      outline: none;
      border-color: rgb(var(--orange));

      i {
        color: rgb(var(--orange));
      }
    }

    & .form__control {
      padding: 1rem 1rem 1rem 0;
      margin: 0;
      border: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  i {
    font-size: 2.1rem;
    color: rgb(var(--light-gray));
  }

}

.form {
  &__limit {
    max-width: 37rem;
  }

  &__limit-title {
    max-width: 39rem;
  }

  &__control {
    width: 100%;
    padding: 1.4rem 0.8rem;
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;

    &:focus {
      outline: none;
      border-color: rgb(var(--orange));
    }

    &--border {
      border: 0.1rem solid rgb(var(--light-gray));
      border-radius: 1rem;
    }

    &--border-light {
      border: 0.1rem solid rgb(var(--clear-light-gray));
      border-radius: 1rem;
    }

    &--border-error {
      border: 0.1rem solid rgb(var(--red));
      border-radius: 1rem;
    }

    &--select {
      padding: 0.6rem 0.8rem;
    }

    &--border-blue {
      border: 0.1rem solid rgb(var(--blue-light-electric));
      border-radius: 1rem;
    }
  }

  &__icon-container {
    position: relative;
    padding: 0;
    margin: 0;
  }

  &__input-icon {
    width: 100%;
    padding: 1rem 5rem;
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
    font-size: var(--font-regular);
  }

  &__icon {
    position: absolute;
    top: 2rem;
    left: 2rem;
    font-size: var(--regular-size);

  }

  &__icon-end {
    position: absolute;
    top: 2rem;
    right: 2rem;
    font-size: var(--regular-size);

  }

  &__checkbox-container {
    font-size: var(--regular-size);
    letter-spacing: 0;
    line-height: 2rem;
  }

  &__checkbox-label::before {
    content: '';
    border: 0.2rem solid rgb(var(--light-gray));
    border-radius: none;
    display: inline-block;
    min-height: 2rem;
    min-width: 2rem;
    text-align: center;
  }

  &__checkbox-input {
    position: absolute;
    right: 900rem;

    &:checked+.form__checkbox-label:before {
      color: rgb(var(--orange));
      border-color: rgb(var(--orange));
      background: url('/assets/images/neuromindset/icons-svg/check-orange.svg') no-repeat;
      background-size: 1.2rem;
      background-position-y: 0.3rem;
      background-position-x: 0.2rem;
    }
  }

  &__radius-label {
    display: flex;
    align-items: center;

    &::before {
      content: '';
      border: 0.2rem solid rgb(var(--light-gray));
      border-radius: 50%;
      display: inline-block;
      min-height: 2rem;
      min-width: 2rem;
      text-align: center;
      margin-right: 1rem;
    }

    &::after {
      content: '';
      display: none;
      border-radius: 50%;
      min-height: 0.8rem;
      min-width: 0.8rem;
      background-color: rgb(var(--orange));
      text-align: center;
      position: absolute;
      left: 0.62rem;
      top: 1.65rem;
    }
  }

  &__radius-input {
    visibility: hidden;
    position: absolute;

    &:checked+.form__radius-label:before {
      border-color: rgb(var(--orange));
    }

    &:checked+.form__radius-label:after {
      display: inline-block;
    }

    &--visible {
      visibility: visible;
      position: relative;
    }
  }

  &__password {
    outline: none;
    border: none;
    width: 80%;
    height: 100%;

    &:focus {
      appearance: none;
      outline: none;
    }
  }

  &__password-eye {
    position: absolute;
    right: 1rem;
    border: none;
    background: none;
  }
}


ng-select.form__control--border-error .ng-select-container {
  border: 0
}

.ng-select {
  width: 100%;

  &.user-view__info-select {
    .ng-select-container {
      border-color: rgb(var(--blue-light-electric));
    }
  }

  .ng-select-single .ng-select-container {
    height: 5.4rem;
  }

  .ng-select-container {
    background-color: rgb(var(--white));
    border-color: rgb(var(--light-gray));
    border-radius: 1rem;
    font-size: 1.8rem;
    height: 5.4rem;
    min-height: 5.4rem;
    padding-left: 0.75rem;

    &::after {
      border: 0.1rem solid rgb(var(--white));
      border-radius: 1rem;
      height: 4rem;
      min-height: 4rem;
      transition: none;
    }

    &:hover {
      &::after {
        border: 0.1rem solid rgb(var(--white));
      }
    }

    &:active {
      &::after {
        border: 0.1rem solid rgb(var(--white));
      }
    }

    & .ng-input {
      padding: 0.5rem 2.3rem !important;
    }

    .ng-arrow-wrapper {
      bottom: 0.1rem !important;
      height: 100%;
      margin-right: 1rem;
      position: relative;
      transition: all 100ms linear;

      &::after {
        color: rgb(var(--orange));
        content: '\e900';
        font-family: 'icomoon', sans-serif !important;
        font-size: 1rem;
        position: absolute;
        right: 0;
        top: calc(50% - 0.5rem);
      }

      .ng-arrow {
        border-color: transparent;
      }
    }

    & .ng-clear-wrapper {
      font-size: 3rem;
      bottom: 0;
      right: 2.5rem;

      & .ng-clear {
        font-size: 3rem;
      }
    }

    .ng-placeholder {
      color: rgb(var(--light-gray));
      display: block;
    }

    .ng-value-container {
      align-items: center;
      border: none;
      height: 4rem;
      padding: 0;

      .ng-value-label {
        display: block;

        &::first-letter {
          text-transform: uppercase;
        }
      }
    }
  }

  &.ng-select-opened .ng-select-container,
  &.ng-select-opened .ng-dropdown-panel {
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    overflow: hidden;
    border-color: rgb(var(--orange));

    .ng-arrow-wrapper {
      transform: rotate(180deg);
    }
  }

  &.ng-select-focused {
    .ng-select-container {
      &.ng-appearance-outline {
        &::after {
          border: 0.1rem solid rgb(var(--light-gray));
          transition: none;
        }

        &:hover {
          &::after {
            border: 0.1rem solid rgb(var(--light-gray));
          }
        }

        &:active {
          &::after {
            border: 0.1rem solid rgb(var(--light-gray));
          }
        }
      }

      &::after {
        border: 0;
      }
    }

    &:not(.ng-select-opened)>.ng-select-container {
      border-color: rgb(var(--light-gray));
      box-shadow: none;
    }
  }

  .ng-dropdown-panel {
    border-top: none;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
    background-color: rgb(var(--orange));
    color: rgb(var(--white));
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked:not(.ng-option-selected) {
    background-color: lighten($primary-color, 25%);
  }

  .ng-option-label,
  .ng-option-label.ng-star-inserted,
  .ng-value-label.ng-star-inserted {
    display: block;

    &::first-letter {
      text-transform: uppercase !important;
    }
  }

  &.ng-dirty .ng-select-container .ng-value-container .ng-placeholder,
  &.hasValue .ng-placeholder {
    display: none !important;
  }
}


.angular-editor {
  border-radius: 1rem !important;
}

.angular-editor-textarea {
  margin-top: 0 !important;
}

.angular-editor-toolbar {
  border-radius: 0 0 1rem 1rem !important;
  font-size: 1rem !important;
}

.angular-editor-textarea {
  border-radius: 1rem 1rem 0 0 !important;
}

.label-contrast {
  background: #586477;
  border-radius: .5rem;
  color: white;
  padding: .2rem .3rem;
  opacity: .9;
  width: 7rem;
  text-align: center;

  &--orange {
    background: rgba(var(--orange));
  }

  &--blue {
    background: rgba(var(--blue));
  }

  &--red {
    background: rgba(var(--red));
  }
}

.input-margin {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
}
