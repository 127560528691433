.onboarding {
    display: flex;
    align-items: center;
    justify-content: center;
    animation: fadeIn 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
    
    &__box {
        text-align: center;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        border-radius: 25px;
        background-color: #FFFFFF;
        padding: 10px 30px 56px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__character-text {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80%;
    }

    &__text {
        margin-top: 6px;
    }

    &__character {
        width: 204px;
        height: 204px;
    }

    &__text {
        font-size: 16px;
        color: rgb(var(--color-blue));
        font-family: var(--ff-glacial-bold);
    }

    &__title {
        font-size: var(--title-size);
        color: rgb(var(--color-dark-purple));
        font-family: var(--ff-futura-bold);
    }

    &__options {
        display: flex;
        justify-content: center;
        gap: 24px;
    }

    &__option-img {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        row-gap: 20px;
    }

    &__option-img-element {
        margin-bottom: 20px;
    }

    &__option-text {
        font-size: var(--title-size);
        color: rgb(var(--color-dark-purple));
        font-family: var(--ff-futura-bold);
        width: 273px;
        line-height: 37px;
    }

    &__options-flex-start {
        height: 397px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
}