.fs-small {
  font-size: var(--regular-small-size);
}

.fs-regular {
  font-size: var(--regular-size);
}

.fs-regular-heavy {
  font-size: var(--card-size);
}

.fs-medium {
  font-size: var(--medium-size);
}

.fs-medium-heavy {
  font-size: var(--title-query);
}

.fs-big {
  font-size: var(--big-size);
}

.heading1 {
  font-family: var(--font-heavy);
  color: rgb(var(--light-blue));
  font-size: var(--title-primary-size);
  letter-spacing: 0.1rem;
}
.heading2 {
  font-family: var(--font-medium);
  color: rgb(var(--blue));
  font-size: 2rem;
}
.body1 {
  font-size: var(--medium-size);
  &--heavy {
    font-family: var(--font-heavy);
  }
  @include desktop {
    font-size: var(--regular-size);
  }
}
.body2 {
  user-select: none;
  color: rgba(var(--gray-50));
  font-size: var(--regular-size);
  &--color-white {
    color: rgb(var(--white));
  }
  &--color-black {
    color: rgb(var(--black));
  }
  &--color-blue {
    color: rgb(var(--blue));
  }
}

.body4 {
  font-family: var(--font-heavy);
  font-size: 5rem;
  @include tablet-landscape {
    font-size: var(--title-secondary-size);
  }
  &--color-blue {
    color: rgb(var(--blue)) !important;
  }
}
.body5 {
  font-size: var(--medium-size);
  font-family: var(--font-medium);
  @include tablet-landscape {
    font-size: var(--body5-query);
  }
}
.error-text {
  color: rgb(var(--red));
  border-color: rgb(var(--red));
}
.underlineText {
  color: rgba(var(--orange));
  text-decoration: underline;
}
.infoText {
  font-size: var(--medium-size);
}
.labText {
  font-size: var(--big-size);
  font-family: var(--font-heavy);
}
.body1,
.body3,
.body4,
.body5 {
  color: rgb(var(--black));
}
.text-color {
  &--primary {
    color: rgb(var(--light-blue));
  }
  &--secondary {
    color: rgb(var(--orange));
    :hover {
      color: rgb(var(--orange));
    }
    :focus {
      color: rgb(var(--orange));
    }
    :link {
      color: rgb(var(--orange));
    }
    :visited {
      color: rgb(var(--orange));
    }
  }
  &--header {
    color: rgb(var(--blue));
  }
  &--disabled {
    color: rgb(var(--light-gray));
  }
  &--white {
    color: rgb(var(--white));
    :focus {
      color: rgb(var(--white));
    }
    :hover {
      color: rgb(var(--white));
    }
  }
}

.text-lab {
  &__regular {
    font-size: var(--regular-bigger-size);
  }
  &__bigger {
    font-size: var(--medium-size);
  }
  &__title-size {
    font-size: var(--title-secondary-size);
  }
  &__small {
    font-size: var(--regular-size);
  }
  &__smaller {
    font-size: var(--smaller-size);
  }
}

.text-premium {
  line-height: 2rem;
}

.text-radius {
  width: 15rem;
}
.title-text {
  color: rgb(var(--light-blue));
  &--primary {
    font-size: var(--title-primary-size);
    font-family: var(--font-heavy);
    @include tablet-landscape {
      font-size: var(--title-query);
    }
  }
  &--secondary {
    color: rgb(var(--blue));
    font-size: var(--title-secondary-size);
  }
  &--not-underline {
    text-decoration: none;
  }
}

.disabled-text {
  color: rgba(var(--gray-50));
}
.card-text {
  font-size: var(--card-size);
}
.notification-text {
  white-space: normal;
}

.overWriteFontSize {
  font-size: var(--regular-small-size) !important;
}

.session-active {
  color: rgb(var(--orange));
}

.unit-active {
  font-weight: var(--font-weight-semibold);
}