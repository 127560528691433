/* import what we need to override */
@import './node_modules/bootstrap/scss/functions';
@import './node_modules/bootstrap/scss/variables';

/* set the overriding variables */
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 1920px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1400px,
  xxxl: 1800px,
);

/* override the !default vars with the values we set above */
@import './node_modules/bootstrap/scss/bootstrap';
