.lab-introduction-modal {
  --bs-modal-width: 1132px;
  --bs-modal-border-radius: 25px;

  &__content {
    padding: 37px;

  }

  &__scroll {
    max-height: 710px;
    overflow-y: auto;


    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.05);
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(var(--color-dark-purple), 0.5);
      border-radius: 10px;

    }
  }

  &__close-icon {
    --button-height: 24px;
    background-color: rgb(var(--color-red));
    padding: 5px;
    border-radius: 5px;
    width: 24px;
    cursor: pointer;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 0px rgb(var(--color-dark-red));
    flex-direction: column;
    position: relative;

  }

  &__header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-bottom: none;

    .lab-introduction-modal__close-icon {
      position: absolute;
      top: 6px;
      right: -5px;
    }
  }


  &__section-title {
    font-size: 36px;
    font-family: var(--ff-futura-bold);
    color: rgb(var(--color-blue));
    margin-bottom: 10px;
    margin-left: 23px;
    margin-top: 10px;
  }

  &__description {
    font-size: 16px;
    color: rgb(var(--color-dark-purple));
    font-family: var(--ff-glacial);
    margin-bottom: 30px;
  }

  &__body {
    padding: 20px 20px 0px;
  }

  &__section-subtitle {
    font-size: 18px;
    font-family: var(--ff-glacial-bold);
    color: rgb(var(--color-blue));
    margin-bottom: 15px;
    margin-top: 20px;
  }

  &__list {
    list-style: none;
    padding-left: 0;
    margin-bottom: 60px;

    li {
      font-size: 28px;
      font-family: var(--ff-glacial);
      color: rgb(var(--color-dark-purple));
    }
  }

  &__downloads {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 20px;


    &--item {
      display: flex;
      gap: 15px;
    }

    &--file {
      padding: 12px;
      background-color: rgb(230, 230, 230);
      color: rgba(0, 0, 0, 0.5);
      border-radius: 8px;
      font-size: 14px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 356px;
      padding-left: 30px;
    }
  }


  &__footer {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    gap: 10px;
    border-top: none;
  }
}