@keyframes fadeIn {
    0% { opacity: 0; }
  100% { opacity: 1; }
}


@keyframes slideDown {
    0% { opacity: 0; transform: translateY(-50%); }   
  100% { opacity: 1; transform: translateY(0); }
}


@keyframes pulse-bg {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: -135% 0%;
  }
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}