.labels {
  display: flex;
  column-gap: 1rem;
  row-gap: 1rem;
  flex-wrap: wrap;
  align-items: center;

  &__label {
    border-radius: 1rem;
    height: 3.2rem;
    display: flex;
    align-items: center;
    padding: 1rem 1.5rem;
    color: rgb(var(--color-dark-purple));
    font-size: 1.2rem;
    font-family: var(--ff-glacial);
    min-width: 11rem;
    background-color: #d0f8ff;

    &--0 {
      background-color: #ccf2f1;
    }

    &--1 {
      background-color: #fff0d0;
    }
  }
}
