.lui-modal {
  --bs-modal-width: 113rem;

  & .modal-content {
    border-radius: 2.5rem;
    background: white;
    border: none;
    position: relative;
  }

  &__body {
    padding: 3.7rem 6rem 8rem;
    display: flex;
    flex-direction: column;
    row-gap: 4rem;
  }

  &__close {
    --button-height: 2.4rem;
    border-radius: 5px;
    position: absolute;
    right: 3rem;
    top: 2rem;
    max-width: 2.4rem;
  }

  &__introduction {
    font-family: var(--ff-glacial);
    font-size: 1.6rem;
    color: rgb(var(--color-dark-purple));
  }


  &__section-title {
    font-family: var(--ff-glacial-bold);
    font-size: 1.8rem;
    color: rgb(var(--color-blue));
    text-transform: uppercase;
    margin-bottom: 2rem;
  }

  &__unit-data {
    font-family: var(--ff-glacial);
    font-size: 2.8rem;
    color: rgb(var(--color-dark-purple));
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }
  
  &__remember {
    font-family: var(--ff-glacial);
    font-size: 1.1rem;
    color: rgb(var(--color-dark-purple));
  }

}

.lui-modal-header {
  background: rgb(0, 43, 101);
  height: 29rem;
  display: flex;
  align-items: center;
  border-radius: 2.4rem;
  padding: 0 6.6rem;
  column-gap: 11rem;
  box-shadow: 0px 3px 6px #00000029;

  &__title {
    color: rgb(174, 208, 255);
    font-family: var(--ff-futura-bold);
    font-size: 3.6rem;
    line-height: 5rem;
    max-width: 47.5rem;
    width: 100%;
  }

  &__image {
    max-width: 24rem;
    max-height: 24rem;
  }

}

.lui-modal-swiper {
  position: relative;

  &__frame {
    width: 920px;
    height: 522px;
    border: 1px solid rgb(var(--color-dark-purple));
    border-radius: 2.5rem;
    box-shadow: 0px 3px 6px #00000029;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    position: relative;
  }

  &__swiper {
    max-width: 100%;
    height: 100%;
    padding: 3rem;
    margin-bottom: 15px;
  }

  &__navigate {
    position: absolute;
    width: 8.2rem;
    height: 8.2rem;
    cursor: pointer;
    font-size: 3rem;
    background-color: rgba(var(--color-blue), .8);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    top: calc(50% - 4.1rem);
    z-index: 2;

    &:hover {
      font-size: 3.5rem;
      background-color: rgba(var(--color-blue), 1);
    }

    &--hidden {
      opacity: 0;
    }

    &--left {
      left: -4.8rem;

      &::before {
        content: '\f053';
        font-family: 'Font Awesome 6 Pro';
        font-weight: 400;
      }
    }

    &--right {
      right: -4.8rem;

      &::before {
        content: '\f054';
        font-family: 'Font Awesome 6 Pro';
        font-weight: 400;
      }
    }
  }

  &__slide-title {
    font-family: var(--ff-glacial-bold);
    font-size: 1.8rem;
    color: rgb(var(--color-blue));
    margin-bottom: 2.5rem;
  }

  &__slide-description {
    font-family: var(--ff-glacial);
    font-size: 1.6rem;
    color: rgb(var(--color-dark-purple));
    margin-bottom: 5rem;
  }

  &__slide-content {
    display: flex;
    align-items: center;

    img {
      max-width: 423px;
      max-height: 185px;
    }
  }

  &__slide-content-download {
    flex-grow: 1;
    text-align: center;
    display: flex;
    justify-content: center;

    button {
      width: 17rem;
    }
  }
}