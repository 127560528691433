.angular-editor-toolbar {
  input[type=color]:not(.color-picker) { /* added :not(.color-picker) so that this will be ignored when the package is updated */
    display: initial !important;
    position: absolute;
    visibility: hidden;
    border: none;
    margin: 0;
    padding: 0;  
    height: 0;
    width: 0;
  }
}