.modal-popUp {
  max-width: 80vw;
  height: auto;
  background-color: rgb(var(--white));
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  overflow: hidden;
  border: 0.2rem solid rgb(var(--blue));
  border-radius: 1.5rem;
  ::before {
    display: block;
  }
}
.modal-popUp-header {
  display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-top-left-radius: calc(0.1rem);
    border-top-right-radius: calc(0.1rem);
}

.modal-popUp-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem 10rem;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 3rem;
}

.modal-popUp-footer {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem 10rem;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 3rem;
}


.modal-neuro {
  font-family: 'FuturaStdBook', Roboto;

  & .modal-content {
    border: 0.1rem solid rgb(var(--blue));
  }

  & .modal-header {
    border-bottom: 1px solid #efefef;
  }

  & .modal-footer {
    border-top: 1px solid #efefef;
  }
}
