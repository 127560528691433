.evaluations {
  align-items: baseline;

  &__header {
    display: flex;
    align-items: center;
    margin-left: 60px;
    gap: 20px;
    margin-bottom: 20px;
    text-transform: uppercase;
    cursor: pointer;

    &--active-red {
      border-bottom: 3px solid rgb(var(--color-red));
    }

    &--active-blue {
      border-bottom: 3px solid rgb(var(--color-blue));
    }

    &--active-yellow {
      border-bottom: 3px solid rgb(var(--color-yellow));
    }
  }


  &__card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    height: 128px;
    width: 100%;
    margin-bottom: 20px;
    padding-right: 52px;
    padding-left: 59px;
    border-radius: 25px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    column-gap: 2rem;
  }

  &__percentage {
    width: 100%;
    max-width: 480px;
  }

  &__title {
    font-size: 36px;
    font-family: var(--ff-futura-bold);
    color: rgb(var(--color-blue));
    margin-bottom: 15px;
  }

  &__container {
    display: flex;
    flex-direction: column;
  }

  &__image {
    img {
      width: 100px;
      height: 100px;
    }
  }

  &__info {
    flex-grow: 1;

    & .value {
      color: rgb(var(--color-dark-purple));
      font-size: 27px;
      font-family: var(--ff-glacial-bold);
    }
  }

  &__unit {
    flex: none;
    color: rgb(var(--color-blue));
    font-family: var(--ff-glacial-bold);
    text-align: right;
    margin-right: 40px;
    text-transform: uppercase;
  }

  &__action {
    display: flex;
    gap: 10px;
  }

  &__no-records {
    font-size: 20px;
    font-family: var(--ff-glacial);
    text-align: center;
  }
}