.user-menu{
    border: 0.1rem solid rgb(var(--blue));
    width: 20rem;
    border-radius: 1rem;
    background-color: rgb(var(--white));
    z-index: var(--top);
    right: 0;
    top: 70% !important;
    padding: 2rem 0;
    font-size: var(--regular-small-size);
    text-align: center;
    left: initial !important;

    &--admin {
        top: 12px !important;
        right: -12px !important;
    }

    &__button{
        box-sizing: border-box;
        background: none;
        padding: 1.2rem;
        border: 0;
        transition: all var(--fast);

        &:hover{
            background-color: rgb(var(--gray-10));
        }

        &--danger{
            color: rgb(var(--red));
            &:hover{
                color: rgb(var(--red));
            }
        }
    }

    &::after{
        content: "";
        width: 1.5rem;
        height: 1.5rem;
        border-top: 0.1rem solid rgb(var(--blue));
        border-left: 0.1rem solid rgb(var(--blue));
        background-color: rgb(var(--white));
        transform: rotate(45deg);
        position: absolute;
        top: -0.85rem;
        right: 12%;
    }
}
.dropdown-toggle::after {
    display: none;
}
