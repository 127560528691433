.file-cdn-modal {
  $max-modal-width: 60rem;
  position: relative;
  font-size: 1.4rem;
  border-radius: 1.5rem;
  overflow: hidden;

  &__close {
    cursor: pointer;
    position: absolute;
    right: -.7rem;
    top: -.6rem;
    border-radius: 100%;
    background: rgb(var(--gray));
    width: 2rem;
    height: 2rem;
    text-align: center;
    line-height: 2rem;
    border: 1px solid rgb(105, 105, 105);
    color: rgb(105, 105, 105);
    font-size: 1.2rem;
    z-index: var(--overtop);

    &:hover {
      background: rgb(var(--blue-lightning));
      color: rgb(var(--gray));
    }
  }

  &__container {
    padding: 1.5rem;
    display: grid;
    row-gap: 2rem;
  }

  &--loading {
    pointer-events: none;
    opacity: .6;
  }

  &__modal-container {
    max-width: $max-modal-width;
  }

  &__image {
    max-height: 4.8rem;
    max-width: 5.8rem;
  }

  &__icon {
    font-size: 3.5rem;
    color: rgb(var(--blue-lightning))
  }

  &__grid {
    display: grid;
    column-gap: .5rem;
    row-gap: .5rem;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    min-height: 40rem;
  }

  &__file {
    border-radius: .5rem;
    border: 1px solid rgba(var(--light-gray), 50%);
    padding: 1rem 1.5rem;
    display: flex;
    align-items: center;
    column-gap: 1rem;
    cursor: pointer;
    overflow: hidden;

    &:hover {
      background: rgba(var(--blue-lightning), 10%)
    }

    &--selected {
      border: 1px solid rgba(var(--blue-lightning));
      background: rgba(var(--blue-lightning), 20%)
    }

    &--disabled {
      pointer-events: none;
      & > * {
        opacity: .5;
      }
    }

  }

  &__file-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__file-info {
    display: grid
  }

  &__file-size {
    font-size: 1rem;
    padding: 0.1rem .5rem;
    background: #668eb6;
    border-radius: .5rem;
    font-weight: var(--font-semibold);
    color: rgb(var(--gray));
  }

  &__file-extension {
    font-size: 1rem;
    padding: 0.1rem .5rem;
    background: #ff7955;
    border-radius: .5rem;
    font-weight: var(--font-semibold);
    color: rgb(var(--gray));
    text-transform: uppercase;
    letter-spacing: .1rem;
  }

  &__paginator-container {
    display: flex;
    justify-content: center;
  }

  &__title {
    font-size: 1.8rem;
  }

  &__file-selected {
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    column-gap: .5rem;

    & span {
      font-weight: var(--font-weight-semibold);
    }
  }

  &__file-selected-filename {
    overflow: hidden;
    max-width: 39rem;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__header {
    display: flex;
    flex-wrap: wrap;
    row-gap: 1rem;
    column-gap: 1rem;
    justify-content: space-between;
  }

  &__search-container {
    display: flex;
    align-items: center;
    position: relative;
    flex-grow: 1;
  }

  &__search-input {
    margin: 0;
    padding: 1rem;
  }

  &__search-input-icon {
    position: absolute;
    right: 2rem;
    font-size: 2.3rem;
    color: rgb(var(--light-gray))
  }

  &__tools {
    display: flex;
    row-gap: 1rem;
    column-gap: 1rem;
    align-items: center;
  }

  &__filters-container {
    display: flex;
    row-gap: 1rem;
    column-gap: 1rem;
    height: 100%;
    user-select: none;
  }

  &__filters-type-container {
    display: flex;
    height: 100%;
    align-items: center;
    border-radius: .5rem;
    border: 1px dashed rgb(var(--light-gray));
    padding: .5rem;
    cursor: pointer;

    &--selected {
      background: rgb(var(--blue-lightning));
      font-weight: var(--font-bold);
      color: white;
    }
  }

  &__file-view {
    color: rgb(var(--blue-lightning), 70%);
    font-size: 1.5rem;
    cursor: pointer;

    &:hover {
      color: rgb(var(--blue-lightning), 100%);
    }
  }
}

.pagination {
  --bs-pagination-font-size: 1.4rem !important;
}