.aside-menu {
  display: flex;
  flex-direction: column;
  width: 33.3rem;
  border-top: 1px solid var(--example-gray);
  border-right: 1px solid var(--example-gray);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  position: sticky;
  height: calc(100vh - var(--header-height));
  top: var(--header-height);
  justify-content: space-between;

  @include tablet-portrait{
    padding-top: calc(var(--header-height) + var(--side-nav-collapse-container));
    justify-content: space-between;
    position:initial
  }

  &__content {
    //max-height: calc(100% - 30rem);
    overflow-y: scroll;
    overflow-x: hidden;
  }

  &__footer {
    padding-left: 3rem;
    padding-bottom: 3rem;
    width: 100%;
    background: rgb(var(--gray));
    @include tablet-portrait{
      width: initial;
    }
  }

  &__content{
    flex: 1;
    overflow-y: auto;

    &::-webkit-scrollbar-track
    {
      -webkit-box-shadow: var(--scrollbar-shadow);
      border-radius: 1rem;
      background-color: var(--light-grey);
    }

    &::-webkit-scrollbar
    {
      width: 0.7rem;
      background-color: var(--light-grey);
    }

    &::-webkit-scrollbar-thumb
    {
      border-radius: 1rem;
      -webkit-box-shadow: var(--scrollbar-shadow);
      background-color: rgba(var(--light-blue), 0.5);
    }
  }

  &--collapsed{
    box-shadow:none;
    width: 17rem;
    background-color: transparent;
    height: auto;
    left:0;
  }
}

.aside-layout {
  display: flex;
  margin-top: var(--header-height);
  position: relative;
}

.aside-content {
  flex: 1;
}

.side-menu-title-button {
  padding: 1.5rem 1rem;
  height: var(--side-nav-collapse-container);
  color: rgb(var(--white));
  width: 33.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  &--background {
    background-color: rgb(var(--blue));
  }

  &__button {
    justify-content: right;
    margin-right: -3rem;
  }
  &--collapsed{
    width: 17rem;
  }
}

.side-menu-title-container {
  display: grid;
  padding: 0 1rem;

  &__lab-name {
    font-size: 1.8rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #b8fffd;
  }

  &__lab-no {
    font-size: 2.7rem;
    color: #ffffff;
  }
}

