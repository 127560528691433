.card-activities {
  cursor: pointer;
  user-select: none;
  animation: fadeIn 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  background: white;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 25px;
  position: relative;

  &--scale-on-hover {
    transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);

    &:hover {
      transform: scale(1.02);
    }
  }

  &__wrapper {
    position: relative;
    width: 354px;
    height: 450px;
    display: flex;
    flex-direction: column;
  }    


  &__gradient-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(45, 29, 84, 0.8) 0%,
      rgba(45, 29, 84, 0) 65%
    );
    z-index: 1;
    border-radius: 25px;
  }

  &__img-card {
    margin-bottom: 8px;
    height: 172px;
    border-radius: 25px;
    object-fit: cover;
  }

  &__heart {
    position: absolute;
    top: 18px;
    right: 27px;
    cursor: pointer;
    z-index: 2;
  }

  &__overlay-text {
    position: absolute;
    top: 20%;
    right: 25%;
    font-size: 16px;
    font-family: var(--ff-futura-bold);
    color: white;
    text-align: center;
    display: flex;
    flex-direction: row;
    gap: 8px;
    z-index: 2;
  }

  &__card {
    padding: 0px 24px 18px;
    position: relative;
    z-index: 2;
  }

  &__duration {
    text-align: right;
  }

  &__title {
    font-family: var(--ff-futura-bold);
    color: rgb(var(--color-blue));
    font-size: var(--subtitle-size);
    margin-bottom: 11px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__text-card {
    font-size: 16px;
    font-family: var(--ff-glacial);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 75px;
    margin-bottom: 28px;
  }

  &__icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 28px;
  }

  &__icons-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 13px;
  }
}
