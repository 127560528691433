.lab-layout {
  position: relative;
  display: flex;
  column-gap: 3rem;
  margin-top: 35px;
  animation: fadeIn ease .5s;

  &__menu {
    /*     
    position: sticky;
    top: 135px;
    max-height: calc(100vh - 233px);
    overflow-x: hidden;
    overflow-y: scroll;
    min-width: 350px; 
    */
    min-height: 100vh
  }

  &__content {
    background: white;
    border-radius: 25px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
    width: calc(100% - 360px);    
    flex-grow: 1;
  }
}

.lab-return {
  position: relative;
  top: -15px;
}