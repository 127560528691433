.button {
   font-size: var(--medium-size);
   padding: 1.25rem 3rem;
   border-radius: 1rem;
   font-family: var(--font-heavy);
   color: rgb(var(--white));
   border: none;
   letter-spacing: .1rem;
   user-select: none;

   &--primary {
      background-color: rgba(var(--orange));
   }

   &--secondary {
      background-color: rgba(var(--gray-50));
   }

   &--disabled {
      pointer-events: none;
      background-color: rgba(var(--light-gray));
      opacity: 0.5;
   }

   &--danger {
      background-color: rgba(var(--red));
   }

   &--special-danger {
      background-color: var(--smooth-red);
   }

   &__icon {
      padding-left: 2rem;
   }

   &--notification {
      background: none;
      padding: 0;
   }

   &--round {
      height: 6rem;
      width: 6rem;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
   }

   &--round-blue {
      background-color: rgb(var(--blue));
   }

   &--negative-margin {
      margin-right: -3rem;
   }

   &__circle {
      border: none;
   }

   &--small {
      font-size: var(--small-size);
      padding: 1rem;
   }

   &--modal-top {
      float: right;
      margin-top: -5rem;
      background-color: inherit;
   }

   &--square {
      padding: 1.25rem;
   }

   &--streched {
      padding: 1rem 5rem 1rem 5rem;
      font-size: var(--regular-size);
      font-weight: var(--font-weight-super-light);
   }

   &--superstreched {
      &.button--streched {
         padding: 1rem 8rem 1rem 8rem;
      }
   }

   &--simplestreched {
      &.button--streched {
         padding: 1rem;
      }
   }

   &--clicked {
      &.button--special {
         background-color: rgba(var(--hovered-orange));
      }
   }

   &--special {
      padding: 1rem 2rem;
      font-size: var(--small-size);
      font-weight: var(--font-weight-super-light);
      color: rgb(var(--palid-orange));
      background-color: rgb(var(--white));
      border: 2px solid rgb(var(--blue-lightning));

   }

   &--special-orange {
      background-color: rgb(var(--special-orange));
   }

   &--outlined-orange {
      border: 0.2rem solid rgb(var(--special-orange));
      background-color: rgb(var(--white));
      color: rgb(var(--special-orange));
   }

   &__icon-only {
      border: none;
      background-color: var(--trasnparent);
      font-size: var(--title-query);

      &--purple {
         color: rgb(var(--purple));
      }

      &--lg {
         font-size: var(--title-primary-size);
      }
   }
}

.input-check {
   --toggle-border-color: rgb(var(--color-dark-purple));
   cursor: pointer;

   &__box--color {
      border: 2px solid var(--toggle-border-color);
      border-radius: 5px;
      width: 20px;
      height: 20px;
   }
}