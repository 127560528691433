.card-container {
  max-width: 104rem;
  box-shadow: 0 0 1rem 0 rgba(var(--black), var(--opacity-1));
  margin-bottom: 16.2rem;
  overflow: hidden;
  &--main {
    padding-left: 0.6rem;
  }
  &--border {
    border: 0.1rem solid rgb(var(--blue));
    border-radius: 1rem;
    background-color: rgb(var(--white));
  }
  &--lab {
    max-width: 124rem;
    height: 21rem;
    margin-top: 2rem;
    position: relative;
    padding: 0 2rem;
    margin-bottom: 0;
    overflow: hidden;
    align-items: center;
    @include desktop {
      padding: 1.5rem;
    }
  }
  &--no-shadow {
    box-shadow: none;
  }
  &__image-container-badge {
    max-width: 11.6rem;
    max-height: 20.95rem;
    bottom: -0.05rem;
    right: 0.75rem;
  }
  &--evaluation {
    position: relative;
  }
  &__image-container-graduation {
    width: 21rem;
    height: 21rem;
    right: -10rem;
    top: 0rem;
    display: flex;
    align-items: center;
    border-radius: 50%;
    &--backgroud-gray {
      background-color: rgb(var(--gray-50));
    }
    &--backgroud-yellow {
      background-color: rgb(var(--yellow));
    }
    &--smaller {
      width: 14rem;
      height: 14rem;
    }
    &--smallest{
      width: 8rem;
      height: 8rem;
    }
  }
  &--info {
    width: 58.2rem;
    margin-bottom: 0;
  }
  &--info-extended {
    min-height: 30rem;
    max-height: 45rem;
    margin-bottom: 2rem;
  }
  &--person {
    width: 100%;
    margin-bottom: 0;
  }
  &--licences {
    max-width: 124rem;
    margin-bottom: 2rem;
  }
  &--background-gray {
    background-color: rgb(var(--gray-10));
  }
  &--square {
    width: auto;
    min-width: fit-content;
    height: fit-content;
    margin: 5rem 5rem 2rem 5rem;
    padding: 2rem;
  }
  &--wider {
    max-width: 100%;
  }
  &--centered {
    height: auto;
    text-align: center;
    padding: 1rem 3rem;
  }
  &--fixed-height {
    height: 67rem;
  }
}

.containerPhoto {
  width: 23rem;
  border-radius: 100%;
  position: relative;
  overflow: hidden;
  height: 23rem;
  padding: 0;
  @include tablet-landscape {
    width: 10rem;
    height: 10rem;
  }
  &__teacherPhoto {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
