.notification-icon-container {
  position: relative;
  width: 33px;
  height: 26px;
  display: block;
}

.notification-icon {
  position: absolute;
  left: 0;
  top: 0;
  background-image: url(/assets/images/neuromindset/icons-svg/notification-msn.svg);
  background-size: contain;
  background-repeat: no-repeat;
  height: 25.64px;
  width: 32.25px;
  user-select: none;
  cursor: pointer;

  &__badge {
    position: absolute;
    background: rgb(var(--color-green));
    border-radius: 100%;
    color: white;
    font-size: 11px;
    bottom: -5px;
    left: 16.5px;
    height: 16px;
    width: 16px;
    line-height: 16px;
    text-align: center;
    user-select: none;
    font-family: 'Glacial Indifference Bold';

    &--exceded-count {
      font-size: 10px;
    }

  }

}
