@import 'swiper/scss';
// @import 'swiper/scss/navigation'; // AQUI SE IMPORTARÍAN LOS ESTILOS PARA EL TIPO QUE SE QUIERA USAR: navigation, pagination, effect-cards, etc..

// DOC: https://swiperjs.com/demos#default  https://swiperjs.com/angular

 // ADAPTAR A LOS ESTILOS DEL DISEÑO
.swiper {
  max-width: calc(100vw - 60rem);
}

.fixed-bottom-carrousel {
  position: relative;
top: -5rem;
z-index: $up;
}
.z-index-buttons {
  position: relative;
  z-index: $over;
}



