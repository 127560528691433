
.toggle {
  --toggle-border-color: var(--color-dark-purple);
  --toggle-bg-color: transparent;

  border-radius: 21px;
  width: 67px;
  height: 36px;
  position: relative;
  background: rgb(var(--toggle-bg-color));
  border: 2px solid rgb(var(--toggle-border-color));
  cursor: pointer;

  &--neutral {
    --toggle-border-color: 128, 128, 128;
  }

  &__label {
    --label-bg-color: var(--color-green);
    width: 22px;
    height: 22px;
    background: rgb(var(--label-bg-color));
    display: flex;
    border-radius: 50px;
    transition: all 0.25s ease;
    position: absolute;
    top: 5px;
    left: 5px;  
    cursor: pointer;

    &--left {
      left: 5px;
    }

    &--neutral {
      left: 19.5px;
      --label-bg-color: 128, 128, 128;
    }

    &--right {
      left: 35px;
    }
  }

}