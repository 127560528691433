

.spm-form-modal {
  --bs-modal-border-radius: 25px;
}

.spm-form {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  column-gap: 1rem;
  padding: 3rem;

  &__mm-cvc {
    display: flex;
    column-gap: 1rem;
    align-items: center;
    margin-bottom: 1rem;
  }

  &__pay {
    align-self: center;
  }

  &__input-with-icon {
    position: relative;
    flex-grow: 1;
    i {
      position: absolute;
      right: 2rem;
      top: 50%;
      transform: translateY(-50%);
      color: rgb(var(--color-dark-purple));
    }

    & input {
      text-transform: uppercase;
      &::placeholder {
        color: #CFD7E0;
        text-transform: none;
      }
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    justify-content: center;
    align-items: center;

    & h3 {
      font-size: 2rem;
      color: rgb(var(--color-dark-purple));
      font-family: var(--ff-glacial-bold);
      margin-bottom: 1rem;
    }

    & small {
      font-size: 1.4rem;
      color: rgb(var(--color-dark-purple));
    }
  }
}

.spm-form-success {
  display: flex;
  flex-direction: column;
  row-gap: 4rem;
  align-items: center;
  padding: 3rem;
}