
*::-webkit-scrollbar-track {
  background-color: rgb(var(--scrollbar-bg));
}

*::-webkit-scrollbar {
  width: 10px;
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #e8e8e8
}
