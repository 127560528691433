.activity-list {
  margin-top: 2.5rem;

  // SLIDER MARGIN FIX
  & ngx-slider {
    margin: 0;
  }

  &__clean {
    cursor: pointer;
    margin-left: 1rem;
    background: white;
    border: 1px solid rgb(var(--color-dark-purple));
    border-radius: 18px;
    padding: .5rem 1.3rem;
    display: flex;
    align-items: center;
    column-gap: .8rem;
    font-family: var(--ff-glacial);
    font-size: 16px;

    i {
      font-size: 18px;
    }

    &:hover {
      background: rgb(var(--color-dark-purple));
      color: white;
      border-color: white;
      font-weight: 700;
    }
  }

  &__activities {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-left: 12.5rem;
    height: 50rem;
    margin-bottom: 6rem;

    
    &:before {
      content: '';
      position: absolute;
      z-index: 2;
      top: 0;
      left: -70px;
      width: 100px;
      height: 100%;
      background: linear-gradient(
        -90deg, 
        rgba(0,0,0,0) 0%, 
        rgba(255,230,230,0.49) 50%, 
        rgba(255,230,230,.9) 100%);
    }

    &:after {
      content: '';
      position: absolute;
      z-index: 2;
      top: 0;
      right: -70px;
      width: 100px;
      height: 100%;
      background: linear-gradient(
        90deg, 
        rgba(0,0,0,0) 0%, 
        rgba(255,230,230,0.49) 50%, 
        rgba(255,230,230,.9) 100%);
    }
  }

  &__activities-title {
    display: flex;
    align-items: center;
    column-gap: 1rem;

    & h2 {
      color: rgb(var(--color-dark-purple));
      text-transform: uppercase;
      font-family: var(--ff-futura-bold);
      font-size: 1.6rem;
    }
  }

  &__activities-count {
    font-family: var(--ff-futura);
    font-size: 1.3rem;
    color: black;
    color: rgb(var(--color-dark-purple));
  }

  &__activities-swiper-container {
    position: absolute;
    top: 40px;
    animation: fadeIn ease 0.5s;
  }

  &__activities-swiper {
    min-width: 100%;
    height: 465px;
    overflow: visible;
  }

  &__activities-swiper-slide {
    width: 354px !important;
  }

  &__search {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    position: relative;
    padding-left: 12.5rem;
    margin-bottom: 6rem;
  }

  &__search-activities {
    display: grid;
    column-gap: 3.2rem;
    row-gap: 3.2rem;
    grid-template-columns: repeat(auto-fit, 354px);
    min-height: 678px;
  }
}

.al-skeleton-slides {
  display: flex;
  column-gap: 5rem;
  position: absolute;
  top: 40px;
  left: 125px;

  &__slide {
    height: 460px;
    width: 354px;
  }
}

.al-skeleton-filters {
  border-radius: 2.1rem;
  margin-bottom: 5.4rem;
  margin-top: 2.5rem;
  min-height: 197px;
  width: 100%;
}
