.info-modal{
    padding: 5rem 10rem 5rem 10rem;
    position: relative;
    &__container{

        min-width: 85rem;

        & .modal-content {
            background-color: rgb(var(--white));
            border: 0.2rem solid rgb(var(--blue-light-electric));     
        }
    }

    &__close{
        position: absolute;
        top: 2rem;
        right: 5rem;
        font-size: var(--title-primary-size);
        font-weight: var(--font-weight-light);
    }

    &__body{
        text-align: center;
        max-height: 35rem;
        overflow-y: auto;
        overflow-x: hidden;
    }

    &__title{
        font-size: var(--title-secondary-size);
        margin-bottom: 2rem;
        font-weight: var(--font-weight-semibold);
        text-align: center;
        &--delete {
            font-size: var(--title-query);
        }
    }

    &__subtitle{
        font-size: var(--regular-size);
        font-weight: var(--font-weight-medium);
        margin-bottom: 5rem;
        &--xl{
            font-size: var(--regular-medium-size);
        }
        &--heavier{
            font-weight: var(--font-weight-medium);
        }
    }

    

    &__body {
        font-size: var(--title-secondary-size);
        font-weight: var(--font-weight-medium);
        color: #778291;
    }

    &__body-item{
        margin-bottom: 1.5rem;
    }
}