.input-stack {
  display: flex;
  row-gap: 0.8rem;
  flex-direction: column;


  &--nowrap {
    text-wrap: nowrap;
  }

  &--disable {
    pointer-events: none;
    opacity: .85;
  }
}

.input-text {
  background-color: rgb(var(--input-bg));
  border-radius: var(--input-radius);
  border: none;
  color: rgb(var(--color-green));
  font-size: var(--form-input-size);
  height: var(--input-height);
  padding: var(--input-padding);

  &:focus {
    outline: none;
    border-color: rgb(var(--color-dark-purple));
  }
}

.input-text-icon {
  background-color: rgb(var(--input-bg));
  border-radius: var(--input-radius);
  border: none;
  font-size: var(--form-input-size);
  height: var(--input-height);

  & input {
    height: 100%;
    width: calc(100% - 50px);
    border: 0;
    background: transparent;
    color: rgb(var(--color-green));
    padding: 0 36px;
  }

  & input:focus {
    outline: none;
    border-color: rgb(var(--color-dark-purple));
  }
}

.input-error {
  border: 0.1rem solid rgb(var(--red));
  color: rgb(var(--red));
}

.input-select {
  background-color: rgb(var(--input-bg));
  border-radius: var(--input-radius);
  border: none;
  color: rgb(var(--color-green));
  cursor: pointer;
  font-size: var(--form-input-size);
  height: var(--input-height);
  padding: var(--input-padding) 15px 30px;

  &:focus {
    outline: none;
  }
}

.input-label {
  padding: 0 var(--input-padding-x);
}

.input-group-icon {
  background: rgb(var(--input-bg));
  border-radius: var(--input-radius);
  display: flex;
  align-items: center;
  padding-right: 10px;
  max-height: 5.5rem;

  input {
    width: 100%;
    border: none;
    background: none;
  }

  &--left {
    flex-direction: row-reverse;

    & .input-group-icon {
      padding: 0 15px 0 35px;
    }
  }

  &__icon {
    cursor: pointer;
    padding: 0 35px 0 15px;
    overflow: visible;
  } 

  &--password {
    & input[type="text"] ~ .input-group-icon__icon {
      background: url('/assets/images/neuromindset/icons-svg/eye-open.svg') no-repeat center;
    }  
    & input[type="password"] ~ .input-group-icon__icon {
      background: url('/assets/images/neuromindset/icons-svg/close-eye.svg') no-repeat center;
    } 

    & input ~ .input-group-icon__icon {
      width: 50px;
      height: 24px;
    }
  }

  &--search {
    & input ~ .input-group-icon__icon {
      background: url('/assets/images/neuromindset/icons/search.png') no-repeat center;
      width: 60px;
      height: 26px;
    } 

  }
}