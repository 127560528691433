// SCSS ---> LOGIN AND FORGOT PASSWORD
.welcome {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 100px);

  &__logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 83px;
  }

  &__box {
    text-align: center;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 23px;
    background-color: rgb(255, 255, 255);
    width: 545px;
    //height: calc(376px + 124px);
    position: relative;
  }

  &__title {
    color: #ffffff;
    width: 545px;
    height: 124px;
    line-height: 124px;
    border-radius: 25px 25px 0px 0px;
    background-color: rgb(var(--color-blue));
  }

  &__text {
    font-family: var(--ff-glacial);
    font-size: 16px;
    align-items: center;
    color: rgb(var(--color-blue));
    margin-top: 44px;
  }

  &__link {
    color: rgb(var(--color-blue));
    font-family: var(--ff-glacial-bold);
    cursor: pointer;
  }

  &__content {
    display: flex;
    flex-direction: column;
    row-gap: 33px;
    min-height: 390px;
    justify-content: center;
    align-items: center;

    &--home {
      row-gap: 50px;
      padding: 60px 75px;
    }

    &--login {
      row-gap: 20px;
      padding: 33px 60px 40px 60px;
    }

    &--password {
      display: block;
      row-gap: 50px;
      padding: 90px 60px 40px 60px;

    }

    &--password-token {
      padding: 33px 60px 40px 60px;
    }
  }

  &__label {
    color: rgb(var(--color-dark-purple));
    font-family: var(--ff-glacial);
    font-size: var(--form-field-size);
    text-align: start;
  }

  &__text-password {
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
  }

  &__text-register {
    left: 0;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    right: 0;
    text-align: center;
    bottom: -35px;
  }
}