.header {
  height: var(--header-height);
  z-index: var(--header);
  &--fixed {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
  }

  &--background-white {
    background-color: rgb(var(--white));
  }

  &--shadow {
    box-shadow: 0 5px 10px 0 rgba(var(--black), var(--opacity-1));
  }

  &__logoWraper {
    width: 5rem;
    border-radius: 100%;
    height: 5rem;
    overflow: hidden;
  }

  &__logo {
    max-height: var(--header-height);
    &--profilePhoto {
      object-position: center;
      height: 100%;
      width: 100%;
    }
    &--profilPhotoCover {
      object-fit: cover;
    }
    &--profilePhotoContain {
      object-fit: contain;
    }
  }
  &__logo-container {
    max-height: var(--header-height);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  &__mainLogo {
    max-height: var(--header-height);
    max-width: 30rem;
  }
  &__navbar {
    padding: 1rem 2rem 1rem 3.5rem;
  }
}
