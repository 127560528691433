:root {
  // BACKGROUNDS
  --bg-blue: 231, 239, 251;
  --bg-green: 229, 248, 248;
  --bg-orange: 255, 235, 230;
  --bg-yellow: 255, 247, 231;

  // COLORS
  --color-blue: 18, 102, 216;
  --color-green: 2, 191, 184;
  --color-orange: 255, 124, 22;
  --color-pink: 255, 90, 164;
  --color-red: 240, 24, 96;
  --color-yellow: 255, 181, 22;

  // COLORS LIGHT
  --color-light-grey: 202, 202, 202;
  --color-light-pink: 246, 154, 195;

  // COLORS DARK
  --color-dark-blue: 3, 55, 124;
  --color-dark-green: 8, 111, 107;
  --color-dark-grey: 130, 130, 130;
  --color-dark-orange: 213, 98, 8;
  --color-dark-purple: 45, 29, 84;
  --color-dark-red: 172, 12, 65;

  // FONT SIZES
  --form-field-size: 18px;
  --form-input-size: 20px;
  --form-title-size: 18px;
  --information-text-size: 12px;
  --label-text-size: 12px;
  --paragraph-size: 18px;
  --subtitle-size: 24px;
  --title-size: 36px;

  // FONTS
  --ff-futura-light: 'Futura Light';
  --ff-futura: 'Futura';
  --ff-futura-medium: 'Futura Medium';
  --ff-futura-bold: 'Futura Bold';
  --ff-glacial: 'Glacial Indifference Regular';
  --ff-glacial-italic: 'Glacial Indifference Italic';
  --ff-glacial-bold: 'Glacial Indifference Bold';

  // FORM INPUT COLORS
  --input-bg: 242, 242, 242;

  // FORM GENERAL PROPS
  --input-padding-y: 15px;
  --input-padding-x: 35px;
  --input-padding: var(--input-padding-y) var(--input-padding-x);
  --input-height: 53px;
  --input-radius: 20px;

  // BUTTON PROPS
  --button-padding: 15px 35px;
  --button-height: 40px;
  --button-width: 155px;
  --button-radius: 17px;
  --button-font-size: 14px;
  --button-font-color: 255, 255, 255;

  --button-big-height: 53px;
  --button-big-width: 185px;
  --button-big-font-size: 18px;
  --button-big-font-family: var(--ff-futura-bold);

  --scrollbar-bg: 255, 255, 255;
}