.lsi-intro-swiper {
  position: relative;
  padding: 5rem 16.8rem 0rem;

  &__container {
    border-radius: 2.5rem 2.5rem 0 0;
    border: 1px solid rgb(var(--color-dark-purple));
    padding: 4.5rem;
    box-shadow: 0px 3px 6px #00000029;
    min-height: 52.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  &__slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  &__slide-title {
    font-family: var(--ff-futura-bold);
    font-size: var(--title-size);
    color: rgb(var(--color-blue));
    text-align: center;
    margin-top: 79px;
  }

  &__slide-content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 49px;
  }

  &__navigate {
    position: absolute;
    width: 8.2rem;
    height: 8.2rem;
    cursor: pointer;
    margin-top: 55px;
    transition: font-size 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955),
      background-color 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) opacity 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    font-size: 3rem;
    background-color: rgba(var(--color-blue), 0.8);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    top: calc(50% - 4.1rem);
    z-index: 2;
    opacity: 1;

    &:hover {
      font-size: 3.5rem;
      background-color: rgba(var(--color-blue), 1);
    }

    &--hidden {
      opacity: 0;
    }

    &--left {
      left: -4.8rem;

      &::before {
        content: '\f053';
        font-family: 'Font Awesome 6 Pro';
        font-weight: 400;
      }
    }

    &--right {
      right: -4.8rem;

      &::before {
        content: '\f054';
        font-family: 'Font Awesome 6 Pro';
        font-weight: 400;
      }
    }
  }

  &__controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(var(--color-dark-purple));
    padding: 0rem 4.5rem;
    width: 100%;
  }

  &__thumbs {
    display: flex;
    align-items: center;

    &-swiper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 117px;


      .swiper-slide {
        cursor: pointer;
        flex-shrink: 1 !important;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          max-height: 51px;
          max-width: 89px;
          transition: border 0.3s ease;
          margin-right: 10px;
        }

        img.selected {
          border: 3px solid rgb(var(--color-green));
          border-radius: 10px;
          padding: 7px;
        }
      }
    }
  }

    &__button-container {
      margin-left: auto;
      padding-left: 1rem;
    }
  
    &__start-button {
      padding: 1rem 2rem;
    }
}