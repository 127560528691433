.users-upload {
    &__container{
        background-color: rgb(var(--white));
        border: 0.2rem solid rgb(var(--blue-light-electric));
        padding: 2rem 1.5rem;
        border-radius: 1rem;
        margin-top: 5rem;
    }

    &__info{
        text-align: center;
        padding-top: 5rem;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    &__info-text{
        font-size: var(--regular-size);
        font-weight: var(--font-weight-semibold);

        &--lighter {
            font-weight: var(--font-weight-regular);
        }
    }
    &__info-title{
        color: rgb(var(--blue-light-electric));
        font-size: var(--medium-size);
        font-weight: var(--font-weight-semibold);
    }
}