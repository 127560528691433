.students {
  margin-top: 25px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-left: 20px;
    gap: 10px;
    padding: 0 10px;
    text-transform: uppercase;
    margin-bottom: 20px;
 
  }

  &__header--left {
    display: flex;
    flex-direction: column;
  }

  &__header--right {
    display: flex;
    gap: 10px;
  }

  &__title {
    font-size: 36px;
    font-family: var(--ff-futura-bold);
    color: rgb(var(--color-blue));
    text-transform: uppercase;
  }



  &__container {
    display: flex;
    flex-direction: column;
    gap: 23px;
    flex-wrap: wrap;
  }

  &__card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 6rem;
    background-color: white;
    height: 128px;
    width: 100%;
    min-width: min-content;
    padding-right: 52px;
    padding-left: 59px;
    border-radius: 25px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  &__image {
    img {
      width: 100px ;
      height: 100px;
      border-radius: 100%;
      border: 2px solid rgb(var(--color-dark-purple));
      object-fit: cover;
    }
  }

  &__info {
    display: flex;
    flex-grow: 1;
    column-gap: 1rem;
    color: rgb(var(--color-dark-purple));
  }

  &__info-column {
    position: relative;
    flex-grow: 1;

    .label {
      position: absolute;
      top: -20px;
      left: 0;
      font-size: 14px;
      font-family: var(--ff-glacial);
    }

    .value {
      font-size: 27px;
      font-family: var(--ff-glacial-bold);
    }
  }

  /*     
  &__email {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 25px;
    margin-right: 225px; 
    
    .label {
      font-size: 14px;
      font-family: var(--ff-glacial);
    }

    .value {
      font-size: 27px;
      font-family: var(--ff-glacial-bold);
      margin: 0;
    } 
  }
  */

  &__action {
    display: flex;
    gap: 10px;
  }
}