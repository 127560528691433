.progress-container--percentage {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-end;
}

.progress-unit {
  width: 60%;
  background-color: #e0e0e0;
  overflow: hidden;
  height: 26px;
  border-radius: 81px;
  border: 2px solid #2d1d54;
  position: relative;

  &__text {
    text-align: end;
    font-size: 18px;
    font-family: var(--ff-glacial);
  }

  &__bar {
  
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: rgba(45, 29, 84, 0.2);
    transition: width 0.4s ease;
    //min-width: 50px;
  }

  &__percentage {  
    font-size: 1rem;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
  }
}

.half-filled {
  background-color: rgba(118, 199, 192, 0.5);
}

.bar-zero {
  background-color: transparent !important;
  /* Evita relleno de fondo */
  width: 0 !important;
  /* Fuerza el ancho a 0 si es 0% */
}
