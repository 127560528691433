.activity-list-filters {
  --filters-bg: 234, 214, 216;
  background-color: rgb(var(--filters-bg));
  border-radius: 2.1rem;
  padding: 2rem 2.7rem 3.3rem;
  margin-bottom: 5.4rem;
  margin-top: 2.5rem;
  display: grid;
  row-gap: 1.5rem;
  animation: fadeIn ease 0.5s;
  
  &--disabled {
    filter: grayscale(1);
    pointer-events: none;
    cursor: not-allowed;
  }
  
  &__row {
    display: flex;
    column-gap: 2rem;
    align-items: center;

    &--first {
      flex-wrap: wrap;
      row-gap: 1.5rem;
    }
  }
  
  // SEARCH INPUT
  &__input-search {
    border: 2px solid rgb(var(--color-dark-purple));
    background: rgb(var(--filters-bg));
    flex-grow: 1;
    max-width: 270px;

    & input {
      color: rgb(var(--color-dark-purple));
      font-size: 18px;
      padding: 15px 0;

     &::placeholder {
        color: rgb(var(--color-dark-purple));
        font-size: 18px;
      }
    }
  }

  // SWITCH TRI STATE INDIVIDUAL/GROUP
  &__switch {
    display: flex;
    align-items: center;
    gap: 2rem;
  }

  &__switch-option {
    display: flex;
    flex-direction: column;
    row-gap: 2px;
    font-size: 12px;
    font-family: var(--ff-glacial);
    color: rgb(var(--color-dark-purple));
    align-items: center;
    opacity: .8;

    & img {
      max-width: 3rem;
    }

    &--active {
      opacity: 1
    }
  }


  // SELECT DIFFICULTY
  &__input-select {
    & * {
      color: rgb(var(--color-dark-purple)) !important;
      font-family: var(--ff-glacial) !important;
      font-size: 18px !important;
    }

    & .ng-select .ng-select-container {
      background-color: rgb(var(--filters-bg));
      border-radius: 21px;
      border: 2px solid rgb(var(--color-dark-purple)) !important;
      width: 205px;
      padding: 15px 10px 15px 20px;

      &:has(span.ng-clear-wrapper) .ng-value-container .ng-input {
        padding-right: 5rem !important;
      }
    }

    & .ng-arrow-wrapper {

      width: auto;
      margin: 0;

      &::after {
        color: rgb(var(--color-dark-purple)) !important;
        font-size: 6px !important;
      }
    }

    & .ng-select-bottom {
      margin-top: 0px;
    }

    & .ng-select-opened .ng-dropdown-panel {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      border-color: rgb(var(--color-dark-purple)) !important;
      border-width: 2px;
    }

    & .ng-option.ng-option-marked:not(.ng-option-selected) {
      background-color: rgb(var(--filters-bg));
    }

    & .ng-option-selected {
      background-color: rgb(var(--color-dark-purple)) !important;
      & .ng-option-label { 
        color: white !important;
      }
    }

    & .ng-clear-wrapper {
      right: 1rem !important;
      width: 12px;
    }

    & .ng-clear {
      font-size: 3rem !important;
    }
  }

  // AREAS
  &__areas {
    display: flex;
    column-gap: 3.4rem;
    align-items: center;
  }

  &__areas-button {
    background: white;
    box-shadow: 0 8px rgb(var(--color-dark-purple));
    border: 2px solid rgb(var(--color-dark-purple));
    color: rgb(var(--color-dark-purple));
    font-family: var(--ff-glacial);
    font-size: 18px;
    letter-spacing: .1px;
    min-width: 247px;
    height: 55px;
    display: flex;
    align-items: center;
    column-gap: 15px;
    padding: 0 20px;
    text-wrap: nowrap;
    box-sizing: content-box;

    &:active {
      transform: translateY(5px);
      box-shadow: 0 -5px rgb(var(--color-dark-purple));
    }
  }

  &__labels {
    display: flex;
    column-gap: 1.5rem;
  }
}

#areas-dropdown {
  position: absolute;
  top: -59px;
  border: 2px solid rgb(var(--color-dark-purple));
  border-radius: 16px;
  width: 300px;
  left: 0px;
  animation: fadeIn cubic-bezier(0, 0, 0.58, 1) .1s;
  padding: 2rem;
  font-size: 14px;
  font-family: var(--ff-glacial);
  color: rgb(var(--color-dark-purple))
}

#dropdown-container bs-dropdown-container {
  z-index: 3 !important;
}

.ad-container {
  background: white;
  position: relative;

  &__close {
    position: absolute;
    right: 2rem;
    top: 2rem;
    cursor: pointer;
    font-size: 16px;
  }

  &__title {
    color: rgb(var(--color-dark-purple));
    font-family: var(--ff-glacial-bold);
    font-size: 18px;
    letter-spacing: .1px;
    display: flex;
    align-items: center;
    column-gap: 15px;
    margin-bottom: 1rem;
  }


  &__group {
    color: rgb(var(--color-dark-purple));
    font-family: var(--ff-glacial);
    font-size: 18px;
    position: relative;
    cursor: pointer;
    transition: transform .2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    padding: .5rem;
    border-radius: .5rem;
    user-select: none;

    &:hover {
      background-color: rgba(var(--color-dark-purple), .1);
      transform: scale(1.01);
      & > .ad-container__chevron {
        color: rgb(var(--color-dark-purple));
        font-weight: 700;
      }
    }

    & .fa-circle {
      font-size: .8rem;
    }
  }

  &__chevron {
    position: absolute;
    right: 1rem;
    top: calc(50% - .5rem);
    font-size: 1.2rem;
  }

  &__children {
    padding-left: 2rem;
  }

  &__item {
    display: flex;
    align-items: center;
    column-gap: 1rem;
    color: rgb(var(--color-dark-purple));
    font-family: var(--ff-glacial);
    font-size: 18px;
    cursor: pointer;

    & label {
      flex-grow: 1;
      cursor: pointer;
    }
  }

}

.activity-list-filters-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 3rem;

  i {
    font-size: 64px;
    color: rgb(var(--color-red));
  }

  h1 {
    font-size: 24px;
    color: rgb(var(--color-dark-purple));
    font-family: var(--ff-glacial-bold);
  }

}

