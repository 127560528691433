typeahead-container.dropdown-menu {
  --bs-dropdown-font-size: var(--regular-small-size);

  &.dropdown-menu {
    width: calc(100% - 2rem);
      
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: 10px;
      background-color: white;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: var(--bs-dropdown-border-radius);
      background-color: #d6d6d6
    }

  }
}