@charset "UTF-8";

// 1. Include overwritte bootstrap
@import 'bootstrap';

// 2. Abstracts and variables
@import 'abstracts/all';

// 3. All the basic UI-Kit
@import 'ui-kit/all';

// 4. Structure pages
@import 'structures/all';

// 5. Font Awesome 6 Pro
@import 'font-awesome/all';

// 6. Select
@import "~@ng-select/ng-select/themes/default.theme.css";

// 7. Ngx Bootstrap Datepicker
@import 'ngx-bootstrap/datepicker/bs-datepicker.css';

/* V2 */

// 1. Fonts
@import 'fonts/all';
// 2. Abstracts and variables
@import 'v2/main';