.notification {
  width: 54rem;
  border: 0.1rem solid rgb(var(--blue));
  border-radius: 1rem;
  background-color: rgb(var(--white));
  z-index: var(--top);
  top: 8rem;
  right: 0rem;
  left: unset !important;
}
.dot {
  right: 2.6rem;
  bottom: -1.2rem;
  color: rgb(var(--orange));
}

.notification-container {
  width: 3.1rem;
  height: 2.2rem;
  &:before {
    content: '';
    display: flex;
    align-items: center;
    width: 0.75rem;
    height: 0.75rem;
    background-color: rgba(var(--orange));
    border-radius: 50%;
    right: 0rem;
    top: 0rem;
  }
}
