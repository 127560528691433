.collapse-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  user-select: none;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 13px;
    gap: 35px;
    margin-left: 5px;
    max-height: 66px;
  }

  &__percentage {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: right;
    color: rgb(var(--color-dark-purple));

    &--title {
      font-family: var(--ff-glacial-bold);
      font-size: 7px;
      margin-right: 10px;
      cursor: pointer;
    }

    &--number {
      font-size: 35px;
      font-family: var(--ff-futura-bold);
    }
  }

  &__title {
    font-size: 19px;
    color: rgb(var(--color-red));
    font-family: var(--ff-glacial-bold);
    text-transform: uppercase;
    margin-left: 6px;
    margin-bottom: 9px;
    cursor: pointer;
  }

  &__unit {
    margin-bottom: 14px;
    position: relative;
  }

  &__unit-chevron {
    z-index: 2;
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      & .fa-circle {
        color: white;
      }

      & .fa-stack-1x {
        color: rgb(var(--color-red))
      }
    }

    & .fa-circle {
      color: transparent;
      transition: color .2s ease-in;
    }

  }

  &__unit-loading {
    font-size: 2.5rem;
  }

  &__button {
    width: 314px;
    height: 66px;
    border-radius: 25px;
    border: none;
    font-family: var(--ff-glacial-bold);
    font-size: 19px;
    text-transform: uppercase;
    color: white;
    text-align: left;
    padding: 15px;
    background-color: rgb(var(--color-dark-grey));
    display: flex;
    align-items: center;
    user-select: none;

    &:disabled {
      background-color: rgb(var(--color-light-grey));
      cursor: default;
    }

    &--active {
      background-color: rgb(var(--color-red));
    }

    &--no-events {
      pointer-events: none;
    }

    &:hover {
      background-color: rgb(205, 0, 68);
    }
  }

  &__button-title {
    max-width: 25rem;
  }

  &__subtitle {
    font-size: 19px;
    color: rgb(var(--color-dark-purple));
    font-family: var(--ff-glacial-bold);
    display: flex;
    margin-left: 35px;
    margin-top: 10px;
  }

  &__session-list {
    margin-top: 10px;
    list-style: none;
    margin-bottom: 14px;
  }

  &__session {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 314px;
    height: 49px;
    padding-left: 38px;
    padding-right: 12px;
    margin-bottom: 14px;
    background-color: white;
    border: none;
    border-radius: 15px;
    font-size: 19px;
    font-family: var(--ff-glacial-bold);
    text-transform: uppercase;
    cursor: pointer;
    color: rgb(var(--color-green));
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);

    &:disabled {
      background-color: rgb(var(--color-light-grey));
      color: rgb(var(--color-dark-grey));
      cursor: default;
    }

    &--active {
      background-color: rgb(var(--color-green));
      color: white;
    }
  }

  &__session.highlight-session {
    background-color: rgb(var(--color-green));
    color: white;
  }

  &__icon {
    width: 30px;
    height: 30px;
  }
}