.user-view {
    &__tab-container{
        background-color: rgb(var(--white));
        border: 0.2rem solid rgb(var(--blue-light-electric));
        border-radius: 1rem;
    }

    &__tabset{
        background-color: rgb(var(--white));
        border-top-left-radius: 0.375rem;
        border-top-right-radius: 0.375rem;
    }

    &__title{
        color: rgb(var(--blue-light-electric));
        font-size: var(--regular-medium-size);
        font-weight: var(--font-weight-medium);
    }

    &__info-display{
        background-color: rgb(var(--white));
        border: 0.15rem solid rgb(var(--blue-light-electric));
    
        &--select {
           border-radius: 1rem;
        }
    }

    &__info-block{
        column-gap: 10rem;
    }

    &__image-placeholder{
        font-size: 12rem;
    }
    &__image-container{

        & img {
            border-radius: 3rem;
            width: 300px;
            padding: 2rem;
        }
    }

    &__profile-img-uploader{
        width: 35rem;
    }

    &__submit-block{
        margin-top: 5rem;
    }

    &__teachers-to-add{
        color: var(--electric-green);
    }
    
    &__added-teacher-icon{
        color: var(--smooth-green);
    }

    &__teachers-summary{
        color: var(--electric-green);
        font-size: var(--regular-size);
    }

    &__labs-wrapper{
        column-gap: 3rem;
    }

    &__lab-container{
        border: 0.2rem solid rgb(var(--blue-light-electric));
        border-radius: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3rem;
        max-width: 50rem;
    }

    &__lab-info{
        display: flex;
        column-gap: 2rem;
        align-items: center;
    }

    &__lab-details{
        border: 0.2rem solid rgb(var(--blue-light-electric));
        border-radius: 1rem;
        column-gap: 2rem;
        padding: 1rem;
        align-items: center;
  
    }

    &__dates-container{
        display: flex;
        column-gap: 2.5rem;
    }

    &__date-element {
        width: 50%;
    }

    &__date-label{
        font-size: var(--regular-size);
        font-weight: var(--font-weight-medium);
    }

    &__lab-buttons-container{
        width: 75%;
        display: flex;
        flex-direction: column;
        row-gap: 1.5rem;
        align-content: flex-end;
    }

    &__lab-title{
        display: flex;
        align-self: baseline;
        flex-direction: column;
    }

    &__lines-container{
        max-height: 27rem;
        overflow-y: auto;
    }

    &__lines-table {
        width: 100%;
    }

    &__line-info{
        padding: 2.5rem 0 2.5rem 0;
        text-align: center;
    }

    &__license-options{
        display: flex;
        align-items: center;
        column-gap: 1rem;
    }

    &__programs-wrapper{
        column-gap: 1.5rem;
    }
}