.cursor-pointer {
  cursor: pointer;
}

.cursor-hand {
  cursor: grab;
}

.column-gap-10 {
  column-gap: 1rem;
}

.column-gap-5 {
  column-gap: .5rem;
}

.content-header-fixed {
  padding-top: var(--header-height);
}

.content-header-general-fixed {
  padding-top: var(--header-height);
}
.content-header-small-fixed {
  padding-top: calc(var(--header-height) / 2);
}

.color-red {
  color: red;
}

.color-gray {
  color: gray;
}

.ellipsis {
  display: block;
  max-width: 17rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


.auto-ellipsis {
  position: relative;

  &:before {
    content: '&nbsp;';
    visibility: hidden;
  }

  & span {
    position: absolute;
    left: 0;
    right: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

[contenteditable] {
  outline: 0px solid transparent;
}
