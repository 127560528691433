.loading {
  align-items: center;
  background: rgb(var(--gray));
  display: flex;
  height: calc(100% - 17.5rem);
  justify-content: center;
  left: 0;
  top: 0;
  opacity: var(--loading-opacity);
  position: absolute;
  width: 100%;
  z-index: 99999;
  height: 100%;
  animation: var(--loading-animation) ease-in-out .2s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
  
  &--no-bg {
    background-color: transparent;
  }
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: var(--loading-opacity);
  }
}
