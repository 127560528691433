.student-modal {
  --bs-modal-width: 1132px;
  --bs-modal-border-radius: 25px;

  &__content {
    padding: 20px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-bottom: none;
  }

  &__title {
    font-size: 24px;
    font-family: var(--ff-futura-bold);
    color: rgb(var(--color-blue));
  }

  &__section-title {
    font-size: 18px;
    font-family: var(--ff-glacial);
    color: rgb(var(--color-blue));

  }

  &__body {
    padding: 20px;
  }

  &__image-title {
    font-size: 18px;
    font-family: var(--ff-glacial);
    color: rgb(var(--color-blue));
    margin-bottom: 22px;
    margin-top: 20px;
  }


  &__row {
    display: flex;
    gap: 15px;
  }

  &__col {
    flex: 1;
  }

  &__label {
    font-size: 18px;
    color: rgb(var(--color-dark-purple));
    font-family: var(--ff-glacial);
    margin-left: 30px;

  }

  &__input {
    width: 100%;
    border-radius: 25px;
    padding: 10px;
    border: none;
    background-color: rgba(0, 0, 0, 0.05);
    margin-bottom: 20px;
  }

  .input-label {
    margin-top: 20px;
  }

  &__info-text {
    font-size: 18px;
    color: rgb(var(--color-dark-purple));
    font-family: var(--ff-glacial);
    margin: 30px;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    gap: 10px;
    border-top: none;
  }

    .link--orange {
      color: rgb(var(--color-orange));
      text-decoration: underline;
      font-family: var(--ff-glacial-bold)
    }
}