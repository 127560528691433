.lab {
  display: block;
  margin: 3.5rem 3.5rem 6rem 3.5rem;
  &--collapsed {
    margin-left: 21rem;
  }
  &__description {
    display: flex;
    margin: 2.5rem 0;
    max-width: 100%;
    &--inside-card {
      margin: 0 6rem;
    }
    &--text {
      font-size: var(--regular-bigger-size);
    }
    &--image {
      max-width: 31rem;
      object-fit: contain;
      object-position: top;
    }
  }
  &__description-text-card {
    font-size: var(--regular-bigger-size);
  }

  &__index {
    display: flex;
    margin: 1rem 0;
  }

  &__index-img {
    width: auto;
    height: 2.2rem;
  }

  &__pdf-img {
    width: 10rem;
    height: 10rem;
  }

  &__pdf-contents {
    height: 100vh;
    max-height: 55rem;
  }

  &__card {
    width: 100%;
    box-shadow: 0 0 1rem 0 rgba(var(--black), var(--opacity-1));
    margin: 2rem 0 2.2rem 0;
    padding: 4rem;
    border: 0.1rem solid rgb(var(--blue));
    border-radius: 1rem;
    background-color: rgb(var(--white));
    &--background-gray {
      background-color: rgb(var(--gray-10));
    }
    &--no-border {
      box-shadow: none;
      border: none;
      background-color: transparent;
    }
    &--no-menu {
      width: auto;
      margin: 4rem 12rem 2.2rem 12rem;
    }
    &--margin-bottom-large {
      margin-bottom: 10rem;
    }
    &--contain {
      object-fit: contain;
      align-items: center;
      overflow: hidden;
      & div {
        height: 50vh;
        object-fit: contain;
      }
    }
  }
  &__card-img-characters {
    position: absolute;
    top: -17rem;
    z-index: -1;
    &--right {
      right: -14rem;
    }
    &--left {
      left: -14rem;
    }
  }

  &__button-tabs {
    width: fit-content;
    margin: 1rem 1rem;
    background-color: rgb(var(--white));
    $color-array: (
      --yellow: rgb(var(--yellow)),
      --green: rgb(var(--light-green)),
      --magent: rgb(var(--pink)),
      --blue: rgb(var(--dark-blue)),
      --black: rgb(var(--black)),
      --pink: rgb(var(--light-pink)),
    );
    @each $color-name, $color-value in $color-array {
      &#{$color-name} {
        border: 0.1rem solid $color-value;
        color: $color-value;
      }
      &#{$color-name}-active {
        background-color: $color-value;
        color: rgb(var(--white));
      }
    }
  }

  &__final-screen {
    & .final-screen__content-container {
      background-color: rgb(var(--white));
      background-image: url('../../assets/images/neuromindset/secondary-images/confeti.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      border: 0.1rem solid rgb(var(--blue));
      box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.1);
      border-radius: 2rem;
      padding: 4rem 13rem 6rem;
      margin-bottom: 12rem;
      margin-right: auto;
      margin-left: auto;
      max-width: 100rem;
      & .final-screen__neuritas-image {
        object-fit: contain;
        height: 5rem;
      }
      & .final-screen__emojis-item {
        margin: 0 1rem;
        filter: grayscale(1);
        transition: $global-transition;
        &:hover,
        &:active,
        &--selected {
          filter: grayscale(0);
        }
      }
      &--collapsed {
        margin-left: 22rem;
      }
    }
  }
}
