.statistics{

    &__outer-container{
        margin-top: 7.5rem;
    }

    &__title{
        color: rgb(var(--blue-light-electric));
        font-size: var(--title-secondary-size);
        font-weight: var(--font-weight-semibold);

        &--smaller {
            font-size: var(--regular-medium-size);
        }
    }

    &__container{
        padding: 2rem
    }

    &__subtitle{
        font-size: var(--medium-size);
        font-weight: var(--font-weight-medium);
        color: var(--special-grey);
        text-align: justify;
    }

    &__value{
        margin-top: 1.5rem;
        padding-left: 1.5rem;
        color: rgb(var(--palid-orange));
        font-size: var(--title-primary-size);
        font-weight: var(--font-weight-semibold);
        &--small{
            font-size: var(--medium-size);
            font-weight: var(--font-weight-medium);
            margin: 0;
        }
    }

    &__rating-container{
        display: flex;
        column-gap: 2rem;
    }
    &__rating-face{
        width: 5rem;
        height: 5rem;
        margin-top: 1.5rem;
    }
    &__message{
        
        color: rgb(var(--palid-orange));
    }

    &__tab-select{
        width: 50%;
    }

    &__status-container{
        display: flex;
        align-items: center;
        font-size: var(--regular-bigger-size);
        &--active {
            color: var(--electric-green);
        }
        &--inactive {
            color: var(--smooth-red);
        }
    }

    &__last-login{
        font-size: var(--smaller-size);
        color: var(--special-grey);
    }
}