.lsi-intro-instructions {
  padding: 0rem 16.8rem 9.5rem;

  &__container {
    background: rgba(var(--color-light-pink), 0.1);
    padding: 44px;
    border-radius: 0 0 2.5rem 2.5rem;
    border: 1px solid rgb(var(--color-dark-purple));
    box-shadow: 0px 3px 6px #00000029;
  }

  &__detail {
    font-family: var(--ff-glacial);
    font-size: 1.4rem;
    color: rgb(var(--color-dark-purple));
    margin-bottom: 1.5rem;
    line-height: 1.6;
  }

  &__description {
    margin-bottom: 1rem;
  }
}