.activity-view {
  display: flex;
  justify-content: center;
  
  &__container {
    width: 100%;
    max-width: 113.2rem;
    display: flex;
    flex-direction: column;
  }

  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 5rem;
  }

  &__heading-title {
    font-family: var(--ff-futura-bold);
    font-size: 2.4rem;
    color: rgb(var(--color-blue));
  }

  &__heading-props {
    display: flex;
    align-items: center;
    column-gap: 4rem;
  }

  &__heading-props-prop {
    display: flex;
    align-items: center;
    column-gap: 1rem;
    
    & span  { text-transform: capitalize }
  }

  &__image {
    border-radius: 2.5rem;
    height: 40rem;
    margin-bottom: 2rem;
    box-shadow: 0px 3px 6px #00000029;
    background-repeat: no-repeat;
    overflow: hidden;
    background-position: center;
  }

  &__image-completed {
    height: 100%;
    background-color: rgba(var(--color-dark-purple), .7);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.4rem;
    color: white;
    font-family: var(--ff-futura-bold);
  }

  &__content {
    padding: 0rem 5rem;
    margin-bottom: 6rem;
  }

  &__content-duration {
    margin-bottom: 1rem;
    text-align: end;
  }

  &__content-labels {
    column-gap: 1rem;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 3.7rem;    
    row-gap: 1rem;
  }

  &__content-label {
    border-radius: 1rem;
    color: rgb(var(--color-dark-purple));
    font-family: var(--ff-glacial);
    font-size: 1.2rem;
    min-width: 15rem;
    padding: 1rem 1.5rem;

    &--category {
      background-color: rgba(var(--color-green), .5);
    }

    &--theme {
      background-color: rgba(var(--color-blue), .5);
    }

    &--process {
      background-color: rgba(var(--color-orange), .5);
    }

    &--subprocess {
      background-color: rgba(var(--color-yellow), .5);
    }
  }

  &__content-description {
    color: rgb(var(--color-dark-purple));
    font-family: var(--ff-glacial);
    font-size: 1.6rem;
    margin-bottom: 3.7rem;
  }

  &__content-buttons {
    display: flex;
    column-gap: 1rem;
  }

  &__content-related-title {
    color: rgb(var(--color-dark-purple));
    font-size: 1.6rem;
    font-family: var(--ff-futura-bold);
    text-transform: uppercase;
    padding: 0 5rem;
    margin-bottom: 3.7rem;
  }

  &__content-related-activities {
    display: flex;
    column-gap: 2rem;
  }


}