.table-competence {
    width: 1378px;
    height: 136px;
    border-radius: 25px 25px 25px 25px;
    overflow: hidden;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    margin-top: 60px;
    margin-bottom: 32px;

    &__btn-edit {
        text-align: end;
    }

    &__row {
        background-color: white;

        &--header {
            color: white;
            font-family: var(--ff-glacial-bold);
            font-size: 21px;
            text-align: center;

            // Quitar el borde inferior de la última fila del encabezado 
            .table-competence__cell {
                border-bottom: none;
            }
        }

        // Quitar el borde inferior de la última fila
        &:last-child {
            .table-competence__cell {
                border-bottom: none;
            }
        }
    }

    &__cell {
        width: 264px;
        height: 140px;
        vertical-align: middle;
        padding: 0 18px;
        border-bottom: 1px solid rgb(var(--color-dark-purple));
        cursor: pointer;

        //Para la primera celda
        &:first-child {
            border-right: 1px solid rgb(var(--color-dark-purple));
        }

        // Para la última celda
        &:last-child {
            border-left: 1px solid rgb(var(--color-dark-purple));
        }

        // Para las celdas que están en el medio
        &:not(:first-child):not(:last-child) {
            border-left: 1px solid rgb(var(--color-dark-purple));
            border-right: 1px solid rgb(var(--color-dark-purple));

        }

        &--first-column {
            font-family: var(--ff-glacial-bold);
            font-size: 20px;
            text-align: center;
            width: 320px;
        }

        &--purple-color {
            background: rgb(var(--color-dark-purple));

        }

        &--green {
            background: rgb(var(--color-green));
            padding: 51px 60px 31px;
        }

        &--yellow {
            background: rgb(var(--color-yellow));
            padding: 51px 60px 31px;
        }

        &--pink {
            background: rgb(var(--color-light-pink));
            padding: 51px 60px 31px;
        }

        &--red {
            background: rgb(var(--color-red));
            padding: 51px 60px 31px;
        }

        &--opacity-g {
            background: rgba(2, 191, 184, 0.15);
        }

        &--opacity-r {
            background: rgba(240, 24, 96, 0.15);
        }

        &--opacity-p {
            background: rgb(246, 154, 195, 0.15);
        }

        &--opacity-y {
            background: rgba(255, 181, 22, 0.15)
        }
    }

    &__buttons {
        display: flex;
        flex-direction: row;
        gap: 15px;
        justify-content: flex-end;
    }

    &__no-data-container {
        font-family: var(--ff-glacial-indifference);
        font-size: 24px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left: 350px;
        margin-top: 130px;
    }

    &__small-icon {
        width: 500px;
    }
}