.lab-session {
  padding: 40px;
  border-radius: 10px;

  &__header {
    margin-bottom: 1.5rem;
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: baseline;

    h1 {
      font-family: var(--ff-futura-bold);
      font-size: 24px;
      color: rgb(var(--color-blue));
      text-transform: uppercase;
    }

    h2 {
      font-family: var(--ff-futura-bold);
      font-size: 24px;
      color: rgb(var(--color-dark-purple));
      text-transform: uppercase;
    }
  }

  &__labels {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 2rem;
  }
}