.layout-waves {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: -1;

  --wave-color: #1266d8;

  &--bg-blue {
    --wave-color: #1266d8;
  }

  &--bg-yellow {
    --wave-color: #2d1d54;
  }

  &--bg-orange {
    --wave-color: #2d1d54;
  }

  &--bg-green {
    --wave-color: #1266d8;
  }

  &__wave-color {
    fill: var(--wave-color);
  }
}