ng-multiselect-dropdown {
  color: #757575;

  .dropdown-btn {
    border-radius: 1rem !important;
    padding: 1.3rem 0.8rem !important;
    min-height: 5.4rem !important;
  }

  .dropdown-multiselect__caret {
    height: 100% !important;
    border-color: #ff5526 transparent;
  }

  .selected-item {
    border-radius: .8rem !important;
    max-width: 20rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    column-gap: .5rem;
    background: #1266d8 !important;
  }

  &.form__control--border-error .dropdown-btn {
    border: 0 !important;
  }

  &.md-categories .selected-item {
    background: #105abe !important;
    border: 1px solid #104ea1 !important;
  }

  &.md-processes .selected-item {
    border: 1px solid #ff4400 !important;
    background: #ff5526 !important;
  }

  &.md-subprocesses .selected-item {
    background: #666777 !important;
    border: 1px solid #595b6f !important;
  }
} 
