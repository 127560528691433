:root {
  font-size: 62.5%; // To use rem as 10px
  --fs-base: 1.6rem;
  --header-height: 15.9rem;
  --side-nav-collapse-container: 10rem;

  --loading-opacity: var(--smooth);
  --loading-animation: fadeInAnimation;
  --gray: 255, 255, 255;
  --light-blue: 1, 191, 185;
  --blue: 18, 103, 216;
  --gray-50: 162, 167, 175;
  --black: 0, 0, 0;
  --white: 255, 255, 255;
  --orange: 255, 85, 38;
  --blue-lightning: 0, 105, 255;
  --blue-light-electric: 82, 144, 255;
  --palid-orange: 255, 144, 82;
  --hovered-orange: 255, 137, 72, 0.16;
  --special-orange: 255, 137, 72;
  --transparent: transparent;
  --light-gray: 162, 167, 175;
  --clear-light-gray: 215, 215, 215;
  --gray-10: 231, 241, 253;
  --gray-15: 240, 244, 249;
  --yellow: 255, 181, 23;
  --light-green: 138, 213, 175;
  --pink: 247, 155, 195;
  --dark-blue: 4, 70, 131;
  --dark-green: 73, 104, 87;
  --light-pink: 255, 187, 182;
  --red: 187, 30, 30;
  --purple: 186,99,198;
  --light-grey: #F5F5F5;
  --electric-green: #77D353;
  --smooth-green: #30AE91;
  --special-grey: #778291;
  --scrollbar-shadow: inset 0 0 6px rgba(0,0,0,.3);
  --opacity-1: 0.10;
  --smooth-red: #F95F62;

  --under: -1;
  --over: 15;
  --top: 25;
  --modal: 50;
  --header: 99;

  --regular-size: 1.8rem;
  --medium-size: 2.2rem;
  --big-size: 5rem;
  --title-primary-size: 3.6rem;
  --title-secondary-size: 3rem;
  --card-size: 2rem;
  --regular-bigger-size: 2rem;
  --body5-query: 1.5rem;
  --title-query: 2.8rem;
  --regular-small-size: 1.6rem;
  --smaller-size: 1.4rem;
  --regular-medium-size: 2.5rem;

  --font-heavy: "FuturaStdHeavy";
  --font-regular: "FuturaStdBook";
  --font-medium: "FuturaStdMedium";

  --font-weight-super-light: 100;
  --font-weight-lighter: 200;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;
  --font-weight-extrabold: 800;

  --md-width: 76.8rem;
  --lg-width: 99.2rem;
  --xl-width: 120rem;

  --fast: 0.3s ease;
  --medium: 1s ease;
  --slow: 1.5s ease;

  --modal: 999;
  --overtop: 99;
  --top: 25;
  --undertop: 2;
  --over: 1;
  --back: -1;

  --invisible: 0;
  --hidden: 0.2;
  --medium: 0.5;
  --smooth: 0.7;
  --almost-visible: 0.96;
  --visible: 1;
}

$primary-color: #FF5526;

$global-transition: all .3s ease;

$breakpoint-xs: 250px;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1440px;
$breakpoint-xxxl: 1920px;

//Z-INDEX
$under: -1;
$up: 1;
$over: 10;
$top:25;
$modal: 99
