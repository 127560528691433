.progress-container {
  width: 100%;
  max-width: min(1770px, calc(100vw - 155px));
  height: 171px;
  display: flex;
  column-gap: 48px;
  background: #fff0d1;
  column-gap: 48px;
  padding: 0px 70px;
  align-items: center;
  border-radius: 21px;
  animation: fadeIn ease .5s;
  
  &__unit-litle {
    color: rgb(var(--color-blue));
    font-family: var(--ff-glacial-bold);
    font-size: 26px;
  }

  &__percentage {
    margin-left: 52px;

    &--title {
      font-size: 12px;
      font-family: var(--ff-glacial-bold);
      color: rgb(var(--color-dark-purple));
    }

    &--number {
      font-size: 84px;
      font-family: var(--ff-futura-bold);
      color: rgb(var(--color-dark-purple));
    }

  }


  &__bar {
    position: absolute;
    background-color: transparent;
    border: 2px solid rgb(var(--color-dark-purple));
    border-radius: 10px;
    width: 100%;
    height: 8px;
    top: 67px;
    z-index: 1;
  }

  &__bar-progress {
    width: 0%;
    height: 100%;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: rgba(var(--color-dark-purple), .2);
  }

  &__sessions {
    z-index: 2;
    width: 1104px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    position: relative;
  }

  &__sessions-list {
    z-index: 3;
    display: flex;
    position: relative;
    justify-content: space-between;
    padding: 0 15px;
  }


  .session {

    &__icon {
      width: 46px;
      height: 46px;
      position: relative;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;

      &--completed {
        background-image: url('/assets/images/neuromindset/icons-svg/check-green.svg');
      }

      &--locked {
        background-image: url('/assets/images/neuromindset/icons-svg/lock-grey.svg');
      }
    }

    &__label {
      margin-top: 4px;
      font-size: 19px;
      color: rgb(var(--color-dark-purple));
      font-family: var(--ff-glacial-bold);

    }
  }

}