@font-face {
  font-family: 'Glacial Indifference Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Glacial Indifference Regular'), url('GlacialIndifference-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Glacial Indifference Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Glacial Indifference Italic'), url('GlacialIndifference-Italic.otf') format('opentype');
}

@font-face {
  font-family: 'Glacial Indifference Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Glacial Indifference Bold'), url('GlacialIndifference-Bold.otf') format('opentype');
}