.evaluation-tag{
    &__container{
        position: absolute;
        top: 0;
        right: 5rem;
        width: 4rem;
        height: 5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: var(--medium-size);
        color: rgb(var(--white));
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        &--completed{
            background-color: rgb(var(--light-green));
        }
        &--uncompleted{
            background-color: rgb(var(--yellow));
        }
    }
}