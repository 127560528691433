.lsi-intro-header {
  background: rgb(0, 43, 101);
  border-radius: 25px 25px 0px 0px;
  padding: 0 15rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56.4rem;

  &__name {
    font-family: var(--ff-futura-bold);
    font-size: 7.8rem;
    color: rgb(174, 208, 255);
    max-width: 50rem;
    margin-bottom: 2.5rem;
  }

  &__labels {
    margin-bottom: 6.8rem;
  }

  &__image {
    max-width: 52rem;
    max-height: 50rem;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

}

.lsi-intro {
  display: flex;
  column-gap: 5.5rem;
  padding: 5rem 16.8rem 9.5rem;
  justify-content: space-between;
    
  &__title {
    font-family: var(--ff-glacial-bold);
    font-size: 1.8rem;
    color: rgb(var(--color-blue));
    margin-bottom: 2rem;
  }

  &__info-text {
    font-family: var(--ff-glacial);
    font-size: 1.6rem;
    color: rgb(var(--color-dark-purple));
  }

  &__competencies {
    display: flex;
    flex-direction: column;
    row-gap: .5rem;
  }

  &__competency {    
    padding: 1.5rem 3.5rem;
    font-family: var(--ff-glacial-bold);
    font-size: 1.8rem;
    color: rgb(var(--color-dark-purple));
    background-color: rgba(var(--color-blue), .5);
    border-radius: 15px;
    white-space: break-spaces;
    min-width: 35.8rem;
    line-height: 1.6rem;

    &--0 {
      background-color: #fff0d0;
    }

    &--1 {
      background-color: #81dfdc;
    }

    &--2 {
      background-color: #f69ac3;
    }   
    
    &--3 {
      background-color: #9ae8f6;
    }
  }
}


.lsi-intro-details {
  background: #c4d9f5;
  padding: 5rem 16.8rem 9.5rem;

  &__detail {
    padding: 3rem 0rem;
    border-bottom: 1px solid rgb(var(--color-blue));
    cursor: pointer;

    &:last-child {
      border-bottom: none;
    }

    &:hover .lsi-intro-details__title {
      transform: scale(1.02);
    }
  }

  &__title {
    position: relative;
    font-family: var(--ff-glacial-bold);
    font-size: 2.4rem;
    color: rgb(var(--color-blue));
    transition: transform .2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  &__chevron {
    position: absolute;
    right: 0;
    top: .5rem;
  }

  &__elements {
    display: flex;
    flex-direction: column;
    row-gap: 3.8rem;
    margin: 6.5rem 0;
  }

  &__element {
    display: flex;
    column-gap: 1.5rem;
    align-items: center;
    font-family: var(--ff-glacial-bold);
    font-size: 1.8rem;
    color: rgb(var(--color-dark-purple));
  }
}