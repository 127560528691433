.home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 100px);

    &__logo-container {
        position: fixed;
        top: 30px;
        margin-top: 100px;
    }

    &__logo {
        display: flex;
        justify-content: center;
    }

    &__link {
        color: rgb(var(--color-blue));
        font-family: var(--ff-glacial-bold);
        cursor: pointer;
    }

    &__content {
        display: flex;
        flex-direction: column;
        min-height: 390px;
        justify-content: center;
        align-items: center;
        margin-top: 200px;
    }


    &__text {
        font-family: var(--ff-glacial);
        font-size: 16px;
        align-items: center;
        color: rgb(var(--color-blue));
        margin-bottom: 10px;
    }


    &__roles {
        display: flex;
        flex-direction: column;
        gap: 20px;
        min-height: 100px;
        transition: height 0.5s ease;

        &--register {
            display: flex;
            background-color: #ffffff;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 25px;
        }

        &--placeholder {
            height: 250px;
            opacity: 0;

        }

        &--register-teacher {
            padding: 37px 58px 24px 58px;
            display: flex;
            flex-direction: column;
            gap: 26px;
            align-items: center;
        }

        &--register-center {
            padding: 37px 30px 24px 30px;
            display: flex;
            flex-direction: column;
            gap: 26px;
            align-items: center;
        }

        &--register-text {
            font-size: var(--subtitle-size);
            font-family: var(--ff-futura-bold);
            text-align: center;
        }

        &--btn {
            margin-bottom: 44px;
            margin-top: 83px;
        }
    }

    /* Animación */
    @keyframes curtainOpen {
        from {
            transform: scaleY(0);
            opacity: 0;
        }

        to {
            transform: scaleY(1);
            opacity: 1;
        }
    }

    @keyframes curtainClose {
        from {
            transform: scaleY(1);
            opacity: 1;
        }

        to {
            transform: scaleY(0);
            opacity: 0;
        }
    }

    .curtain-open {
        animation: curtainOpen 0.8s ease-in-out forwards;
        transform-origin: top;
    }

    .curtain-close {
        animation: curtainClose 0.8s ease-in-out forwards;
        transform-origin: top;
    }
}