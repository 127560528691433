.vertical-menu {

  --vm-background: rgb(68 70 85);
  --vm-item-font-color: rgb(255, 255, 255);
  --vm-item-hover-color: rgb(105, 230, 255);
  --vm-item-active-bg: rgb(58 59 72);
  --vm-item-active-color: rgb(94, 227, 255);

  width: 100%;
  height: 100%;
  background: var(--vm-background);
  font-family: 'FuturaStdBook';
  user-select: none;

  &__item {
    color: var(--vm-item-font-color);
    padding: 1.5rem 2rem;
    display: flex;
    column-gap: 1rem;
    align-items: center;
    font-size: 1.8rem;
    cursor: pointer;
    white-space: nowrap;

    transition: 
      background .1s ease-in-out,
      color .2s ease-in-out;

    .vertical-menu__item-icon path {
      transition: fill .2s ease-in-out;
      fill: var(--vm-item-font-color);    
    }

    &--active {
      color: var(--vm-item-active-color);
      background: var(--vm-item-active-bg);
      & .vertical-menu__item-icon path {
        fill: var(--vm-item-active-color);
      }
    }

    &--disabled {
      pointer-events: none;
      opacity: .3;
    }

    &:hover {
      color: var(--vm-item-hover-color);

      &:not(.vertical-menu__item--active) .vertical-menu__item-icon {
        animation: wiggle .4s ease-in-out 1;
        path {
          fill: var(--vm-item-hover-color);
        }
      }
    }
  }

  &__item-icon {
    position: relative;
    height: 2.4rem;
    width: 2.4rem;
    
    svg {
      width: 2.4rem;
      height: 2.4rem;
      position: absolute;
      top: 0px;
    }
  }

  &__item-label {
    white-space: nowrap;
    @include tablet-portrait {
      display: none;
    }
  }
}