.title {
  font-size: var(--title-size);
  font-family: var(--font-heavy);

  &--blue {
    color: rgb(var(--color-blue));
  }

  &--dark {
    color: rgb(var(--color-dark-purple));
  }

  &--activity-section {
    color: rgb(var(--color-red));
    text-transform: uppercase;
  }
}

.subtitle {
  font-size: var(--subtitle-size);
  font-family: var(--font-heavy);

  &--blue {
    color: rgb(var(--color-blue));
  }

  &--dark {
    color: rgb(var(--color-dark-purple));
  }
}

.paragraph {
  font-size: var(--paragraph-size);
  font-family: var(--ff-glacial);
  color: rgb(var(--color-dark-purple));
}

.form-title {
  font-size: var(--form-title-size);
  font-family: var(--ff-glacial);
  color: rgb(var(--color-blue));
  text-transform: uppercase;
}

.form-field {
  font-size: var(--form-field-size);
  color: rgb(var(--color-dark-purple));
  font-family: var(--ff-glacial);
}

.form-data {
  font-size: var(--form-field-size);
  color: rgb(var(--color-blue));
  font-family: var(--ff-glacial);
}

.form-input {
  font-size: var(--form-input-size);
  color: rgb(var(--color-green));
  font-family: var(--ff-glacial);
}

.label-text {
  font-size: var(--label-text-size);
  color: rgb(var(--color-dark-purple));
  font-family: var(--ff-glacial);
}

.information-text {
  font-size: var(--information-text-size);
  color: rgb(var(--color-dark-purple));
  font-family: var(--ff-glacial-bold);
}