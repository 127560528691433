.skeleton {
  animation: skeleton-loading 2s steps(1000) infinite;
  background: linear-gradient(
    65deg,
    #eaeaea 0%,
    #eaeaea 40%,
    #ffffff 50%,
    #eaeaea 75%,
    #eaeaea 100%
  );
  opacity: 0.5;
  background-size: 500px 100%;
  background-position: 0 0, 0 0, 0 0, 0 0;
  border-radius: 21px;

  &--pulse {
    $from: #f5f5f5;
    $to: scale-color($from, $lightness: -10%);

    opacity: 0.5;
    height: 100%;
    width: 100%;
    background: linear-gradient(-90deg, #efefef 0%, #fcfcfc 50%, #efefef 100%);
    background-size: 400% 400%;
    animation: pulse 1.2s ease-in-out infinite;
    border-radius: 21px;
  }

  // HEIGHT
  &--h-10 {
    height: 10%;
  }

  &--h-20 {
    height: 20%;
  }

  &--h-30 {
    height: 30%;
  }

  &--h-40 {
    height: 40%;
  }

  &--h-50 {
    height: 50%;
  }

  &--h-60 {
    height: 60%;
  }

  &--h-70 {
    height: 70%;
  }

  &--h-80 {
    height: 80%;
  }

  &--h-90 {
    height: 90%;
  }

  &--h-100 {
    height: 100%;
  }

  // WIDTH
  &--w-10 {
    width: 10%;
  }

  &--w-20 {
    width: 20%;
  }

  &--w-30 {
    width: 30%;
  }

  &--w-40 {
    width: 40%;
  }

  &--w-50 {
    width: 50%;
  }

  &--w-60 {
    width: 60%;
  }

  &--w-70 {
    width: 70%;
  }

  &--w-80 {
    width: 80%;
  }

  &--w-90 {
    width: 90%;
  }

  &--w-100 {
    width: 100%;
  }
}

@keyframes skeleton-loading {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
