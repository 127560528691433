// OLD FUTURA V1
@font-face {
  font-family: 'FuturaStdHeavy';
  src: url('FuturaStd-Heavy.otf');
}
@font-face {
  font-family: 'FuturaStdBook';
  src: url('FuturaStd-Book.otf');
}
@font-face {
  font-family: 'FuturaStdMedium';
  src: url('FuturaStd-Medium.otf');
}

// NEW FUTURA V2
@font-face {
  font-family: 'Futura Light';
  src: url('FuturaStd-Light.otf');
}

@font-face {
  font-family: 'Futura';
  src: url('FuturaStd-Book.otf');
}

@font-face {
  font-family: 'Futura Medium';
  src: url('FuturaStd-Medium.otf');
}

@font-face {
  font-family: 'Futura Bold';
  src: url('FuturaStd-Bold.otf');
}