.a-management {
  height: calc(100vh - 1rem);
  width: 100%;
  display: grid;
  grid-template-columns: 25rem 1fr;
  font-family: 'FuturaStdBook';
  overflow: hidden;

  @include tablet-portrait {
    grid-template-columns: 6.5rem 1fr;
  }

  &__column {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 6rem auto;

    &--menu {
      position: sticky;
      top: 0;
    }
  }

  &__column-header-left {
    background: #105abe;
    border-right: 1px solid #1959af;
  }

  &__column-header-right {
    background: #1266D8;
    top: 0;
    display: flex;
    justify-content: space-between;
   
  }

  &__column-header-right--actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 2rem;
    padding: 1rem;
    
  }
  

  &__column-menu {
    border-right: 1px solid #3a3b48;
  }

  &__column-content {
    height: calc(100dvh - 6.9rem);
    overflow-y: hidden;
    overflow-x: auto;
    scrollbar-gutter: auto;
    scroll-behavior: smooth;   
    background: white;
  }

  &__logo {
    background-image: url(/assets/images/neuromindset/brand/logo_dark.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 70%;
    height: 6rem;
    min-width: 25rem;
    padding: 1rem;

    @include tablet-portrait {
      min-width: 6rem !important;
      background-image: url(/assets/images/neuromindset/brand/logo_dark_min.png);
      background-size: 50% 50%;
    }
  }


}

.content-container {
  padding: 3rem 1rem 2rem 2rem;
  max-height: 100%;
  overflow-y: scroll;
  scrollbar-gutter: auto;
}