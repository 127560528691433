.formation-modal {
  --bs-modal-width: 1132px;
  --bs-modal-border-radius: 25px;

  &__content {
    padding: 40px;
  }

  &__close-icon {
    background-color: rgb(var(--color-red));
    padding: 5px;
    border-radius: 5px;
    height: 24px;
    width: 24px;
    cursor: pointer;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 0px rgb(var(--color-dark-red));
    position: relative;
  }

  &__header {
    position: relative;


    .formation-modal__close-icon {
      position: absolute;
      top: -7px;
      right: -5px;
    }
  }

  &__section-title {
    font-size: 36px;
    font-family: var(--ff-futura-bold);
    color: rgb(var(--color-blue));
    margin-left: 20px;
  }

  &__description {
    font-size: 16px;
    color: rgb(var(--color-dark-purple));
    font-family: var(--ff-glacial);
    margin-bottom: 30px;
  }

  &__body {
    padding: 20px 20px 0px;
  }

  &__course-title {
    font-size: 18px;
    font-family: var(--ff-glacial);
    color: rgb(var(--color-blue));
    margin-bottom: 25px;
  }

  &__course {
    height: 450px;
    max-height: 450px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 354px;
    max-width: 354px;
    margin-bottom: 30px;
    background-color: rgb(230, 230, 230);
    border-radius: 24px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

    &-image {
      width: 354px;
      height: 172px;
      object-fit: cover;
      border-radius: 24px;
    }

  
    &-name {
      font-size: 22px;
      font-family: var(--ff-futura-bold);
      color: rgb(var(--color-blue));
      margin-bottom: 15px;
      text-align: left;
      margin-left: 18px;
      margin-right: 18px;
      margin-top: 10px;
    }

    &-description {
      font-size: 16px;
      font-family: var(--ff-glacial);
      color: rgb(var(--color-dark-purple));
      text-align: left;
      margin-left: 18px;
      margin-right: 18px;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    gap: 10px;
    padding-top: 10px;

    &--link {
      font-family: var(--ff-glacial);
      color: rgb(var(--color-dark-purple));
    }
  }
}