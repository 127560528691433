.neuro-checkbox {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border: 2px solid rgb(var(--color-dark-purple));
  border-radius: 5px;
  cursor: pointer;
  position: relative;


  &:checked {
    background-color: rgb(var(--color-blue));
    border-color: rgb(var(--color-dark-purple));
  }

  &:checked::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: translate(-50%, -60%) rotate(45deg);
  }
}