.evaluation-criteria{

    &__breadcrumbs {
        color: rgb(var(--palid-orange));

        &--clickable{
            font-weight: var(--font-weight-bold);
            cursor: pointer;
        }
    }

    &__title {
        color: rgb(var(--blue-light-electric));
    }

    &__list-container{
        background-color: rgb(var(--white));
        border: 0.2rem solid rgb(var(--blue-light-electric));
        padding: 2rem 1.5rem;
        min-height: 35rem;
        border-radius: 1rem;

        &--no-border{
            border: none;
        }
    }

    &__items-container{
        max-height: 35rem;
        overflow-y: auto;
    }

    &__form-container{
        padding: 5rem;
        background-color: rgb(var(--white));
        border: 0.2rem solid rgb(var(--blue-light-electric));
        // min-height: 35rem;
        border-radius: 1rem;
        &--table-form{
            min-height: 60rem;
            position: relative;
        }
    }

    &__table-submit{
        position: absolute;
        bottom: 3rem;
    }
    &__table-submit-container{
        margin-top: 10rem;
    }

    &__delete{
        font-size: var(--title-primary-size);
        color: rgb(var(--red));
        cursor: pointer;
        
        &--valid{
            margin-top: 2.5rem;

        }
    }
    &__textarea{
        resize: none;
    }

    &__select{
        & .ng-select-container {
            padding: 2rem 2.3rem !important;
          }
        
    }

    &__name{
        font-size: var(--title-secondary-size);
        overflow-x: clip;
        text-overflow: ellipsis;

        &--green{
            color: rgb(var(--light-green));
        }
        &--yellow{
            color: rgb(var(--yellow));
        }
        @include desktop{
            font-size: var(--medium-size);
            margin-left: 1.5rem;
        }
    }

    &__icon{
        cursor: pointer;
        font-size: var(--title-secondary-size);
        margin-left: 3rem;

        &--no-pointer{
            cursor: default;
        }

        @include desktop{
            font-size: var(--medium-size);
            margin-left: 1.5rem;
        }
    }

    &__option {
        color: var(--black)  !important;
        &--grey {
            color: var(--special-grey)
        }
    }

    &__warning-container{
        display: flex;
        flex-direction: row;
        column-gap: 2rem;
        color: rgb(var(--red));
        font-size: var(--regular-size);
        
        & i {
            font-size: var(--big-size);
        }
        &--small{
            font-size: var(--regular-small-size);

            & i {
                font-size: var(--regular-size);
            }
        }
        
    }

    &__warning-usage{
        font-weight: var(--font-weight-semibold);
    }
}