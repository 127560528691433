@mixin tablet-portrait {
    @media screen and (max-width: $breakpoint-md) {
      @content;
    }
  }
  
  @mixin tablet-landscape {
    @media screen and (max-width: $breakpoint-lg) {
      @content;
    }
  }
  
  @mixin desktop {
    @media screen and (max-width: $breakpoint-xl) {
      @content;
    }
  }
  
  @mixin desktop-xl {
    @media screen and (max-width: $breakpoint-xxl) {
      @content;
    }
  }
  
  @mixin desktop-xxl {
    @media screen and (max-width: $breakpoint-xxxl) {
      @content;
    }
  }
  
@mixin margins{
  @each $type in $margin-types {
    @for $i  from 1 to 7{
      .m#{$type}--#{$i} { 
        margin-#{map-get($margin-types-property, #{$type})}: map-get($margin-sizes, #{$i});
      }
    }
  }
}