.session-end-modal-wrapper {
  --bs-modal-width: 1130px;
}

.session-end-modal {
  padding: 9rem 9rem 4rem 9rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  position: relative;
  
  &__title {
    font-family: var(--ff-glacial-bold);
    font-size: 26px;
    color: rgb(var(--color-blue));
    margin-bottom: 15px;
  }

  &__message {
    font-family: var(--ff-futura-bold);
    font-size: 36px;
    color: rgb(var(--color-dark-purple));
    max-width: 685px;
    margin-bottom: 35px;
  }

  &__valoration {
    font-family: var(--ff-futura-bold);
    font-size: 51px;
    color: rgb(var(--color-blue));
    margin-bottom: 60px;
  }

  &__valoration-faces-container {
    margin-bottom: 73px;
    display: flex;
    column-gap: 2rem;
  }

  &__valoration-face {
    width: 116.58px;
    height: 116.58px;
    transition: transform .2s ease-in-out;

    &:hover {
      cursor: pointer;
      transform: scale(1.105)
    }

    &--1 {
      background-image: url(/assets/images/neuromindset/icons-svg/valoration_1.svg);
      &:hover {
        background-image: url(/assets/images/neuromindset/icons-svg/valoration_1_on.svg);
      }
    }

    &--2 {
      background-image: url(/assets/images/neuromindset/icons-svg/valoration_2.svg);
      &:hover {
        background-image: url(/assets/images/neuromindset/icons-svg/valoration_2_on.svg);
      }
    }

    &--3 {
      background-image: url(/assets/images/neuromindset/icons-svg/valoration_3.svg);
      &:hover {
        background-image: url(/assets/images/neuromindset/icons-svg/valoration_3_on.svg);
      }
    }

    &--4 {
      background-image: url(/assets/images/neuromindset/icons-svg/valoration_4.svg);
      &:hover {
        background-image: url(/assets/images/neuromindset/icons-svg/valoration_4_on.svg);
      }
    }

    &--5 {
      background-image: url(/assets/images/neuromindset/icons-svg/valoration_5.svg);
      &:hover {
        background-image: url(/assets/images/neuromindset/icons-svg/valoration_5_on.svg);
      }
    }
  }

  &__image {
    position: absolute;
    left: 143px;
    top: 0;
  }

  &__reward {
    display: flex;
    row-gap: 1rem;
    flex-direction: column;
    font-family: var(--ff-futura-bold);
    font-size: 36px;
    color: rgb(var(--color-dark-purple));
    box-shadow: 0px 3px 13px #0000008A;
    border-radius: 25px;
    padding: 40px 54px;
    margin-bottom: 3.6rem;
  }

  &__reward-title {
    font-family: var(--ff-futura-bold);
    font-size: 84px;
    color: rgb(var(--color-dark-purple));
  }

  &__coins {
    color: rgb(var(--color-blue))
  }

  &__buttons {
    display: flex;
    column-gap: 2rem;
    align-self: flex-end;
  }
}