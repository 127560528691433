.background {
  min-height: calc(100% - 1rem);
  &--image-1 {
    background: url('../../assets/images/neuromindset/background/background1.png')
      no-repeat;
    background-size: cover;
  }
  &--image-2 {
    background: url('../../assets/images/neuromindset/background/background2.png')
      no-repeat;
    background-size: cover;
  }
  &--color-gray {
    background-color: rgb(var(--gray-10));
    padding: 4rem 4rem 10rem 4rem;
  }
  &--padding-sides {
    padding: 4rem 4rem 10rem 4rem;
  }
  &--extend {
    min-height: calc(100vh - (var(--header-height) + 1rem));
  }
  &--full {
    min-height: 100%;
  }
}

.max-content {
  max-width: max-content;
}

.border-separator {
  border-top: 0.1rem solid rgb(var(--light-blue));
}
.forgot-password {
  color: rgb(var(--orange));
  font-size: var(--medium-size);
  position: absolute;
  right: 0rem;
}

.bar {
  max-width: 32.9rem;
  background-color: rgb(var(--gray-15));
  border-radius: 1.15rem;
  &__background {
    background-color: rgb(var(--light-blue));
    height: 2.4rem;
    border-radius: 1.15rem;
    &--small {
      height: 1.8rem;
    }
  }
  &--container {
    background-color: rgb(var(--white));
  }
  @include desktop {
    max-width: 15rem;
  }
}

.imageTop {
  padding-top: 5.3rem;
}

.public-container {
  flex-basis: calc(100vh - 1rem);
}

.app-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.img {
  &__badge {
    overflow: hidden;
    width: 11.6rem;
    height: 21rem;
  }
  &__icon {
    width: 3.1rem;
    height: 2.2rem;
  }

  &__icon-lab {
    width: 3rem;
    height: 2rem;
    text-align: center;
  }

  &__profilePicIcon {
    width: 2.2rem;
    height: 2.2rem;
  }
  &__lab {
    max-width: 21rem;
    max-height: 13rem;
    width: 100%;
    @include tablet-landscape {
      max-width: 15rem;
      max-height: 10rem;
    }
  }
  &__headerIcon {
    width: 2.6rem;
    height: 2.2rem;
  }
  &__lab-unit-detail {
    max-width: 50%;
    max-height: 50%;
  }
  &__profilePic {
    max-height: 23rem;
    max-width: 23rem;
    @include tablet-landscape {
      max-width: 10rem;
      max-height: 10rem;
    }
    &--list {
      max-height: 8rem;
      max-width: 8rem;
    }
  }
  &__bulk {
    @include tablet-landscape {
      width: 70%;
    }
  }
  &__labProgram {
    max-width: 35rem;
    max-height: 15rem;
  }
  &--margin-top {
    max-width: 21rem;
    max-height: 13rem;
    width: 100%;
    @include tablet-landscape {
      max-width: 15rem;
      max-height: 10rem;
    }
  }
}

.personList {
  max-width: 100rem;
  margin-bottom: 2rem;
  @include tablet-landscape {
    max-width: 50rem;
    margin-bottom: 2rem;
  }
  &__evaluations {
    margin-bottom: 1rem;
  }
  &__header {
    max-width: 102rem;
    margin-bottom: 1rem;
    @include tablet-landscape {
      max-width: 53rem;
    }
  }
  &--wider {
    max-width: 160rem;
    margin-bottom: 1rem;
  }
}

.licenceList {
  max-width: 124rem;
  margin-bottom: 2rem;
}

.uploadFiles {
  width: 61.6rem;
  height: 32.3rem;
}

.indexTop {
  z-index: var(--top);
}
.disabled {
  opacity: 0.3;
  pointer-events: none;
}
.circleIcon {
  max-width: 2rem;
  max-height: 2.5rem;
}
.circle {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  &--premium {
    background-color: rgb(var(--yellow));
  }
  &--standart {
    background-color: rgb(var(--gray-50));
  }
  &--button {
    background-color: rgb(var(--orange));
  }
}
a {
  text-decoration: none;
  &:hover,
  &:focus,
  &:link {
    color: rgb(var(--orange));
  }
}

.rotated {
  transform: rotate(45deg);
}

.link {
  &--selected {
    color: rgb(var(--orange));
    i,
    h2 {
      color: rgb(var(--orange));
    }
  }
}

.mb-40 {
  margin-bottom: 4rem;
}
.margin-bottom-180 {
  margin-bottom: 18rem;
}
.ml-22 {
  margin-left: 22rem;
}
.mr-22 {
  margin-right: 22rem;
}
.errorContainer {
  height: 3rem;
}

.corneredTab {
  width: 100%;
  height: 6rem;
  border-bottom: 4rem solid rgb(var(--light-blue));
  border-right: 2rem solid rgb(var(--white));
  border-left: 2rem solid rgb(var(--white));
  text-align: center;
  color: rgb(var(--white));
  font-family: 'FuturaStdHeavy';
  letter-spacing: 1.1px;

  &--dark {
    border-bottom: 4rem solid #3a5784;
  }
}

.rounded-header {
  width: 100%;
  height: 4rem;
  text-align: center;
  color: rgb(255, 255, 255);
  color: rgb(var(--white));
  font-family: "FuturaStdHeavy";
  display: flex;
  letter-spacing: 1.1px;
  background: rgb(var(--light-blue));
  align-items: center;
  justify-content: center;
  border-radius: .5rem;
  font-size: 1.8rem;
  &--dark {
    background: #586477;
  }
}

.iframe-style {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
}
.iframe-box {
  position: relative;
  padding-bottom: 35.25%;
  padding-top: 0;
  height: 0;
}

.fixed-width {
  width: 80%;
}

.fixed-height {
  height: 54rem;
  contain: content;
  &--smaller {
    height: 15rem;
    position: relative;
    contain: none;
  }
}
.list-with-scroll {
  height: 35rem;
  overflow-x: hidden;
  overflow-y: scroll;
  &--smaller{
    height: 25rem;
  }
}



@keyframes wiggle {
  0% {transform: rotate(10deg);}
  25% {transform: rotate(-10deg);}
  50% {transform: rotate(20deg);}
  75% {transform: rotate(-5deg);}
  100% {transform: rotate(0deg);}
}


.error-alert {
  align-items: center;
  background-color: #ebc4c4;
  border-radius: .5rem;
  column-gap: 1rem;
  display: flex;
  padding: 1rem;

  &__icon {
    font-size: var(--title-query);
    color: red;
  }

  &__msg {
    font-size: var(--fs-small);
  }


}

.rounded-tab {
  background: rgb(var(--light-blue));
  border-radius: 1rem;
  padding: .5rem 1rem;
  text-align: center;
  text-transform: capitalize;
  width: 100%;
  font-family: var(--ff-glacial-bold);
  font-size: 1.8rem;
  color: white;
  letter-spacing: .1rem;
  user-select: none;
}