.sa-form {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
}

.genially-form {
  &__list {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }

  &__item {
    border: 1px dashed rgb(211, 211, 211);
    border-radius: 5px;
    column-gap: 1rem;
    display: flex;
    font-size: 1.6rem;
    justify-content: space-between;
    padding: 0.5rem 1rem;
  }

  &__item-prop {
    column-gap: 1rem;
    display: flex;
    align-items: center;
  }

  &__item-action {
    padding: 0.5rem;
    cursor: pointer;
    transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);

    &:hover {
      color: rgb(var(--color-blue));
      transform: scale(1.1);
    }

    &--delete {
      color: red;
    }

    &--edit {
      color: rgb(var(--color-orange));
    }
  }

  &__item-url {
    font-size: 1.4rem;
  }
}

.content-toggler {
  position: absolute;
  left: 2rem;
  top: 0.8rem;
  font-size: 1.4rem;
  display: flex;
  column-gap: 0.8rem;
  color: rgb(var(--color-blue));
  z-index: 2;

  & label {
    user-select: none;
  }

  &:has(input:checked) label {
    color: white;
  }
}

.content-disable {
  filter: grayscale(1);
  pointer-events: none;
  opacity: 0.5;
}

.sa-container {
  border-bottom: 0.2rem dashed rgba(var(--blue), 0.2);
  padding-bottom: 2rem;
  margin-bottom: 4rem;
}

.instructions {

  display: flex;
  flex-direction: column;
  row-gap: 2rem;

  &__item {
    border: 1px solid rgb(211, 211, 211);
    padding: 1.5rem 2rem;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    position: relative;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    border-radius: 1rem;
  }

  &__title {
    text-transform: uppercase;
    font-size: 1.6rem;
    font-family: var(--ff-futura-bold);
    padding-bottom: 1rem;
    color: rgb(var(--color-blue));

    & i {
      margin-right: 1rem;
    }
  }

  &__files {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding-top: 1rem;
    border-top: 1px dashed rgb(211, 211, 211);

    a {
      color: rgb(var(--color-orange));
    }

    i {
      color: rgb(var(--color-blue));
    }
  }

  &__actions {
    position: absolute;
    right: 1rem;
    top: 1rem;
  }
}
