.training {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 21px;
  padding-bottom: 157px;

  &__title {
    margin-bottom: 48px;
    text-align: center;
  }

  &__card {
    display: flex;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 25px;
    background-color: #ffffff;
    height: 296px;
    animation: fadeIn ease 0.5s;

    & .training__character-bg {
      background-position: center;
      background-repeat: no-repeat;
    }

    &--3 .training__character-bg {
      background-color: rgb(237, 136, 159);
      background-image: url(/assets/images/neuromindset/icons/personaje.png);
      background-size: 370px;
    }

    &--4 .training__character-bg {
      background-color: rgba(36, 179, 237, 1);
      background-image: url(/assets/images/neuromindset/icons/lab-4-monigote.png);
			background-position: center top;
    }

    &--5 .training__character-bg {
      background-color: rgba(253, 164, 9, 1);
      background-image: url(/assets/images/neuromindset/icons/lab-5-monigote.png);
			background-position: center top;
    }
  }

  &__character-bg {
    width: 380px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 25px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: 32px 26px 38px 50px;
    overflow: hidden;
    
  }

  &__labnumber {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__face {
    display: flex;
    align-items: center;
    gap: 14px;
    margin-bottom: 42px;
  }

  &__text-card {
    font-family: var(--ff-glacial);
    color: rgb(var(--color-dark-purple));
    font-size: 16px;
    width: 649px;
    margin-top: 7px;
    margin-bottom: 20px;
    line-height: 1.1;
    overflow: hidden; 
    text-overflow: ellipsis;
  }
}
