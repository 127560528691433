.toolbar {
  display: flex;
  user-select: none;
  //min-height: 6rem;
  height: 100%;
  justify-content: flex-end;
  column-gap: 2rem;

  &__item {
    padding: 1rem 1.5rem;
    color: white;
    display: flex;
    column-gap: 1rem;
    align-items: center;
    cursor: pointer;
    border: 1px solid white;
    border-radius: .5rem;
    

    &--icon-right {
      flex-direction: row-reverse;
    }

    &--disabled {
      pointer-events: none;
      opacity: .3;
    }

    &:hover {
      background: white;
      color: #1266d8;
      font-family: var(--font-heavy);
    }
  } 
  
  &__item-icon {
    font-size: 1.4rem;  
  }
}