.file-uploader {
  --fu-bg: 243, 243, 243;
  --fu-drop-border-color: var(--color-orange);
  border-radius: 2.1rem;
  background-color: rgb(var(--fu-bg));
  display: flex;
  overflow: hidden;
  box-sizing: border-box;
  border: .2rem dashed transparent;
  height: 20.4rem;

  &--drop-over {
    border-color: rgb(var(--fu-drop-border-color));
  }

  &__button-container {
    display: flex;
    align-items: center;
    column-gap: 1rem;
    & div:last-child {
      flex-grow: 1;
      font-size: 1.2rem;
      font-weight: 600;
      color: red;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-self: center;
    row-gap: 2rem;
    padding: 2rem 2rem 2rem 3rem;
    width: 100%;

    // Corregimos los 5px de margen que contemplan el box-shadow del botón.
    & div:last-child {
      margin-top: .5rem;
    }
  }

  &__image {
    position: relative;
    img {
      border-radius: 2rem;
      object-fit: cover;
      height: 20rem;
      width: 15rem;
    }
  }

  &__image-remove {
    align-items: center;
    background-color: rgb(var(--color-blue));
    border-radius: 100%;
    border: 1px solid white;
    color: white;
    cursor: pointer;
    display: flex;
    height: 24px;
    justify-content: center;
    padding: .5rem;
    position: absolute;
    right: 1rem;
    top: 1rem;
    width: 24px;
    z-index: 2;
    transition: transform .1s ease-in-out;

    &:hover {
      background-color: red;
      transform: scale(1.1)
    }
  }

  &__input {  
    position: absolute;
    pointer-events: none;
    z-index: -99;
    visibility: hidden;
    width: .1rem;
  }
}