.neuro-btn {
  // DEFAULT
  --btn-box-color: rgb(var(--color-dark-green));
  --btn-bg-color: rgb(var(--color-green));

  background: var(--btn-bg-color);
  border-radius: var(--button-radius);
  border: none;
  box-shadow: 0 5px var(--btn-box-color);
  color: rgb(var(--button-font-color));
  cursor: pointer;
  font-family: var(--font-heavy);
  font-size: var(--button-font-size);
  height: var(--button-height);
  min-height: var(--button-height);
  width: var(--button-width);
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  letter-spacing: .5px;
  

  &--icon {
    display: flex;
    column-gap: 1rem;
    align-items: center;
    padding: 0 2rem;
    width: max-content;
  }

  &--green {
    --btn-bg-color: rgb(var(--color-green));
    --btn-box-color: rgb(var(--color-dark-green));
  }

  &--red {
    --btn-bg-color: rgb(var(--color-red));
    --btn-box-color: rgb(var(--color-dark-red));
  }

  &--orange {
    --btn-bg-color: rgb(var(--color-orange));
    --btn-box-color: rgb(var(--color-dark-orange));
  }

  &--blue {
    --btn-bg-color: rgb(var(--color-blue));
    --btn-box-color: rgb(var(--color-dark-blue));
  }

  &--light-pink {
    --btn-bg-color: rgb(var(--color-light-pink));
    --btn-box-color: rgb(var(--color-pink));
  }

  &--dark-yellow {
    --btn-bg-color: rgb(var(--color-yellow));
    --btn-box-color: rgb(var(--color-orange));
  }

  &--gray {
    --btn-bg-color: rgb(var(--color-light-grey));
    --btn-box-color: rgb(var(--color-dark-grey));
    color: rgb(var(--color-dark-grey));
  }

  &--dark-gray-blue {
    --btn-bg-color: #817798;
    --btn-box-color: #2d1d54;
    color: white;
  }

  &--inactive {
    --btn-bg-color: rgb(var(--color-light-grey));
    --btn-box-color: rgb(var(--color-dark-grey));
    color: rgb(var(--color-dark-grey));
    pointer-events: none;
    cursor: not-allowed;
  }

  &[disabled] {
    --btn-bg-color: rgb(var(--color-light-grey));
    --btn-box-color: rgb(var(--color-dark-grey));
    color: rgb(var(--color-dark-grey));
    pointer-events: none;
    cursor: not-allowed;
  }

  &--big {
    height: var(--button-big-height);
    width: var(--button-big-width);
    font-size: var(--button-big-font-size);
    font-family: var(--button-big-font-family);
  }

  &:hover {
    transform: translateY(5px);
    box-shadow: none;
  }

  &:active {
    transform: translateY(5px);
    box-shadow: 0 -5px var(--btn-box-color);
  }
}