// scss register teacher and school
.register {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 160px;
    margin-bottom: 60px;

    &__logo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 75px;
    }

    &__title,
    &__text,
    &__text-data {
        text-align: left;
        margin-bottom: 35px;
        margin-top: 35px;
    }

    &__box-form {
        background-color: #FFFFFF;
        padding: 51px 63px 38px;
        text-align: center;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        border-radius: 23px;
        width: 1132px;

    }

    &__form {
        text-align: left;
    }

    &__form-input {
        display: flex;
        flex-direction: row;
        gap: 18px;
        margin-bottom: 35px;
    }

    &__form-input-stack {
        margin-bottom: 35px;
    }

    &__condition-text {
        color: #02BFB8;
        text-decoration: underline;
    }

    &__btn {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 14px;
    }
}