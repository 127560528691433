.management{

    &__option-container{
        padding: 3rem;
        border: 2px solid rgb(var(--blue-lightning));
        color: rgb(var(--palid-orange));
        border-radius: 1rem;
        font-weight: var(--font-weight-bold);
        cursor: pointer;
    }

    &__outer-container{
        min-height: calc(100vh - 21.9rem) ;
    }
}