.slider-container {
	display: flex;
	column-gap: 15px;
	align-items: center;

  &--min-height {
    height: 70px
  }

  &--grow {
    flex-grow: 1;
    max-width: 289px;
    & .ngx-slider {
      width: 100% !important;
      min-width: 150px;
      max-width: 200px;
    }
  }

  &__label {
    color: rgb(var(--color-dark-purple));
    font-size: 18px;
    font-family: var(--ff-glacial)
    //hay que poner font family Glacial Indifference, Regular
  }

  & .ngx-slider {
    width: 231px;
    height: 8px;
    border: 2px solid rgb(var(--color-dark-purple));
    border-radius: 10px;
  }

  & .ngx-slider .ngx-slider-bubble {
    top: 16px;
  }

  & .ngx-slider .ngx-slider-pointer {
		outline: 0px solid transparent;
    position: relative;
    background-color: rgb(var(--color-green)) !important;
		height: 22px;
		width: 22px;
		top: -9px;

    &:after {
      background-color: rgb(var(--color-green)) !important;
    }

    &:hover:after {
      background-color: rgb(var(--color-green)) !important;
    }

    &.ngx-slider-active:after {
      background-color: rgb(var(--color-green)) !important;
    }
  }

  & .ngx-slider .ngx-slider-tooltip {
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
  }
}
