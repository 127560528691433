.hb {
  --hb-default: url('/assets/images/neuromindset/icons-svg/heart.svg');
  --hb-active: url('/assets/images/neuromindset/icons-svg/heart-red.svg');

  background-image: var(--hb-default);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 24px 24px;
  height: 24px;
  width: 24px;
  cursor: pointer;
  transition: background-image .2s ease-in-out;

  &:hover {
    animation: wiggle 1s infinite;
    background-image: var(--hb-active);
  }

  &:active {
    filter: brightness(1.3)
  }

  &--active {
    background-image: var(--hb-active);
  }
}