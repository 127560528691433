

.sortable-list {

  &__editable[contenteditable="true"] {
    outline: 0px;
  }

  &__editable-focus {
    border-color: rgb(var(--orange)) !important;
  }

  &__sortable-ghost {
    border-color: rgb(var(--orange)) !important;
  }

}
