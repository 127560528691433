.evaluations {
    display: flex;
    flex-direction: column;

    &__btn {
        margin-bottom: 11px;
    }

    &__button-gray {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__btn-avatar-text {
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        flex-direction: row;
        gap: 11px;
    }

    &__button-red {
        width: 375px;
        height: 66px;
        border-radius: 25px;
        border: none;
        font-family: var(--ff-glacial-bold);
        font-size: 19px;
        text-transform: uppercase;
        color: white;
        text-align: left;
        padding-left: 15px;
        background-color: rgb(var(--color-red));
        margin-bottom: 16px;
        margin-top: 105px;
    }

    &__text-name {
        color: rgb(var(--color-dark-purple));
    }

    &__button-block {
        width: 376px;
        height: 60px;
        border-radius: 13px;
        border: 2px solid transparent;
        font-size: 16px;
        font-family: var(--ff-glacial-bold);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-left: 16px;
        padding-right: 18px;
        transition: background-color 0.3s ease;

        &--white {
            background-color: rgba(255, 255, 255, 1);
            color: rgb(var(--color-dark-purple));
        }

        &--gray {
            background-color: rgba(210, 210, 210, 1);
            color: rgb(149, 149, 149);

        }

        &--red {
            border: 2px solid rgb(var(--color-red));
        }

        &--green {
            border: 2px solid rgb(var(--color-green));
        }
    }

    &__avatar--gray {
        filter: grayscale(100%) brightness(0.9);
    }

    &__text-unit--blue {
        color: rgb(var(--color-blue));
    }

    &__text-unit--gray {
        color: rgb(149, 149, 149);
    }

    &__button-green {
        width: 375px;
        height: 66px;
        border-radius: 25px;
        border: none;
        font-family: var(--ff-glacial-bold);
        font-size: 19px;
        text-transform: uppercase;
        color: white;
        text-align: left;
        padding-left: 15px;
        background-color: rgb(var(--color-green));
        margin-bottom: 16px;

    }

    &__no-records {
        justify-content: center;
        align-items: center;
        display: flex;
        padding-left: 15px;
        margin-bottom: 30px;
    }
}