.notification-icon-popover {

  --bs-popover-border-width: 1px;
  --bs-popover-border-color: rgb(45, 29, 84);
  --bs-popover-arrow-height: 1.3rem;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-body-padding-x: 16px;
  --bs-popover-body-padding-y: 35px;

  border-radius: 19px;
  border: 2px solid rgb(var(--color-dark-purple));
  top: 9px !important;
  position: absolute;

  &__header {
    font-size: 18px;
    font-family: var(--ff-glacial-bold);
    margin-bottom: 10px;
    color: rgb(var(--color-dark-purple));
  }

  &__list {
    list-style: none;
    margin-top: 10px;
  }

  &__title {
    font-size: 18px;
    font-family: var(--ff-glacial);
    color: rgb(var(--color-dark-purple));
    margin-top: 0px;
    padding-right: 35px;
  }

  &__time {
    font-size: 10px;
    font-family: var(--ff-glacial-bold);
    color: rgb(var(--color-dark-purple));
    opacity: 0.5;
  }

  &__hr {
    background-color: rgb(var(--color-dark-purple));
    opacity: 0.2;
    margin: 7.8px 0;
  }

  &__close {
    position: absolute;
    right: 0px;
    top: -15px;
    cursor: pointer;
  }

  &__view {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }

  &__text-view {
    font-family: var(--ff-glacial-bold);
    font-size: var(--label-text-size);
    text-decoration: underline;
    cursor: pointer;
  }

  .popover-arrow {
    position: absolute;

    &::before,
    &::after {
      content: '';
      position: absolute;
      border-style: solid;
    }

    &::before {
      border-width: 0 calc(var(--bs-popover-arrow-width) * 0.8) var(--bs-popover-arrow-height);
      border-color: transparent transparent var(--bs-popover-border-color) transparent;
    }

    &::after {
      top: calc(var(--bs-popover-border-width) + 2px);
      border-width: 0 calc(var(--bs-popover-arrow-width) * 0.7) calc(var(--bs-popover-arrow-height) * 0.9);
      border-color: transparent transparent var(--bs-popover-bg) transparent;
      left: 1px;
    }
  }
}