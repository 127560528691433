.area-onboarding {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;


  &__return-button {
    align-self: flex-start;
    margin-bottom: 22px;
  
  }

  &__sections {
    display: flex;
    gap: 24px;
    margin-top: 44px;
  }

  &__box {
    background-color: #fff;
    width: 552px;
    height: 532px;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }

  &__image {
    width: 552px;
    height: 308px;
    margin-bottom: 31px;

  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__text {
    font-size: 16px;
    color: rgb(var(--color-blue));
    font-family: var(--ff-glacial-bold);

  }

  &__subtitle {
    font-size: 36px;
    font-family: var(--ff-futura-heavy);
    color: rgb(var(--color-dark-purple));
    margin-top: -6px;
  }

  &__button {
    width:155.01px;
    height: 46.88px;
    margin-top: 15px;
  }
}