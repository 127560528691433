.activity-modal {
    --bs-modal-width: 1132px;
    --bs-modal-border-radius: 25px;

    &__content {
        padding: 72px 57px 42px 62px;

    }

    &__arrow-icon {
        margin-bottom: 18px;
        display: block;
        margin-left: auto;
        cursor: pointer;
    }


    &__title {
        font-size: 24px;
        font-family: var(--ff-futura-bold);
        color: rgb(var(--color-blue));
        margin-bottom: 52px;
    }

    &__data {
        font-family: var(--ff-glacial-bold);
        font-size: 10px;
        color: rgba(45, 29, 84, 0.5);
        margin-bottom: 2px;
    }

    &__title-notification {
        font-family: var(--ff-glacial-bold);
        font-size: var(--form-field-size);
    }

    &__btn-close {
        display: flex;
        justify-content: flex-end;
        margin-top: 63px;
    }

}