@font-face {
  font-display: container;
  font-family: 'icomoon';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/icomoon.eot?o6l8yx');
  src: url('../fonts/icomoon.eot?o6l8yx#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?o6l8yx') format('truetype'),
    url('../fonts/icomoon.woff?o6l8yx') format('woff'),
    url('../fonts/icomoon.svg?o6l8yx#icomoon') format('svg');
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon', sans-serif !important;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: none;
  text-transform: none;
}

.icon-arrowdown::before{
  content:'\e900'
}
.icon-arrowleft::before{
  content:'\e901'
}
.icon-arrowright::before{
  content:'\e902'
}
.icon-arrowup::before{
  content:'\e903'
}
.icon-book::before {
  content: '\e904';
}
.icon-check::before {
  content: '\e905';
}
.icon-Combined-Shape::before {
  content: '\e906';
}
.icon-download::before {
  content: '\e907';
}
.icon-eye::before {
  content: '\e908';
}
.icon-eyehide::before {
  content: '\e909';
}
.icon-graduation::before {
  content: '\e90a';
}
.icon-info::before {
  content: '\e90b';
}
.icon-lock::before {
  content: '\e90c';
}
.icon-message::before {
  content: '\e90d';
}
.icon-openbook::before {
  content: '\e90e';
}
.icon-pen::before {
  content: '\e90f';
}
.icon-shield::before {
  content: '\e910';
}
.icon-users::before {
  content: '\e911';
}
.icon-xmark::before {
  content: '\e912';
}
.icon-share::before {
  content: '\e913'
}
.icon-logout::before {
  content: '\e914'
}




