.neuro-slider .ngx-slider {
  .ngx-slider-pointer:after {
    top: 8px;
    left: 8px;
    width: 8px;
    height: 8px;
  }

  .ngx-slider-pointer {
    width: 24px;
    height: 24px;
    top: -10px;

    &:after {
      background-color: red;
    }

    &:hover:after {
      background-color: red;
    }

    &.ngx-slider-active:after {
      background-color: red !important;
    }
  }

  .ngx-slider-bubble {
    font-size: 14px;
    font-family: 'FuturaStdHeavy';
    color: #1489ae;
  }
}
