.header-new {
  //backdrop-filter: blur(4px);
  margin-bottom: 4rem;
  position: sticky;
  top: 0;
  padding: 4rem 7rem 2rem;
  z-index: 4;

  &__logo img {
    width: 336.6px;
    height: 56.38px;
  }

  &__right-elements {
    padding-top: 14px;

  }

  &__buttons {
    padding-right: 14px;

  }

  &__icon {
    padding: 0 10px;
  }

  &__link {
    font-size: 16px;
    color: rgb(var(--color-dark-purple)) !important;
    font-family: var(--ff-glacial-bold);
    border-left: 1.5px solid rgba(var(--color-dark-purple), 0.5);
    padding: 0 10px;
    user-select: none;

    &--active {
      color: rgb(var(--color-blue)) !important;
      pointer-events: none;
    }

  }


  &__power-icon {
    margin-left: -5px;
    cursor: pointer;
  }

}