.return-button {
  align-items: center;
  background: none;
  border: none;
  color: rgb(var(--color-dark-purple));
  column-gap: 1rem;
  cursor: pointer;
  display: flex;
  font-family: var(--font-heavy);
  color: rgb(var(--color-dark-purple));
  background-size: 24px 24px;
  background-repeat: no-repeat;
  background-position: left center;
  font-size: 16px;
  margin-left: 68px;
  text-decoration: underline;

  &__image {
    background-position: left center;
    background-repeat: no-repeat;
    background-size: 24px 24px;
    height: 24px;
    width: 24px;

    &--bg-blue-home {
      background-image: url(/assets/images/neuromindset/icons-svg/house-green.svg);
    }

    &--bg-yellow-home {
      background-image: url(/assets/images/neuromindset/icons-svg/house-pink.svg);
    }

    &--bg-orange-home {
      background-image: url(/assets/images/neuromindset/icons-svg/house-green.svg);
    }

    &--bg-green-home {
      background-image: url(/assets/images/neuromindset/icons-svg/house-green.svg);
    }

    &--bg-blue-back {
      background-image: url(/assets/images/neuromindset/icons-svg/arrow-back-blue.svg);
      color: rgb(var(--color-blue));
    }

    &--bg-yellow-back {
      background-image: url(/assets/images/neuromindset/icons-svg/arrow-back-pink.svg);
    }

    &--bg-orange-back {
      background-image: url(/assets/images/neuromindset/icons-svg/arrow-back-blue.svg);
      color: rgb(var(--color-blue));
    }

    &--bg-green-back {
      background-image: url(/assets/images/neuromindset/icons-svg/arrow-back-blue.svg);
      color: rgb(var(--color-blue));
    }
  }
}
