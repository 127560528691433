.table {
  width: 100%;
  border-collapse: collapse;
  margin: 3.5rem 3rem;
  padding: 2rem;
  text-align: center;

  &--bordered-rows tbody tr:not(:last-child) td {
    border-bottom: 1px solid #eaf1f9;

  }

  &--row-hover {
    & tbody > tr {
      &:hover {
        background: #eaf1f9;

        & td:not(:last-child) {
          cursor: pointer;
        }
      }
    }
  }

  &--border {
    border-radius: 1rem;
    border-collapse: separate;
    overflow: hidden;
    box-shadow: 0 0 1rem 0 rgba(var(--black), var(--opacity-1));
    background-color: rgb(var(--white));
    border: 0.1rem solid rgb(var(--blue));
  }

  &__container{
    border-radius: 1.5rem;
    box-shadow: 0 0 1rem 0 rgba(var(--black), var(--opacity-1));
    background-color: rgb(var(--white));
    border: 0.1rem solid rgb(var(--blue));
    max-height: 37rem;
    overflow-y: auto;
  }

  &__th {
    color: rgb(var(--blue));
  }

  &--padded-cells td {
    padding: 1rem 0;
  }

  &--border-row td {
    border-bottom: 0.1rem solid rgb(var(--black));
  }

  &--border-row-light td {
    border-bottom: 0.1rem solid rgb(var(--gray-10));
  }

  &__matrix {
    box-shadow: 0 0 1rem 0 rgba(var(--black), var(--opacity-1));
    background-color: rgb(var(--white));
    font-size: var(--medium-size);

    th {
      border-collapse: separate;
      overflow: hidden;
      border: 0.1rem solid rgb(var(--blue));
      background-color: rgb(var(--white));
      padding: 0.75rem;
      height: 10rem;

      & .styled {
        position: absolute;
        font-size: 4rem;
        bottom: -0.5rem;
        width: 100%;
        text-align: end;
        padding-right: 1rem;
        color: rgba(var(--white), 0.5);
      }
    }

    td {
      border-collapse: separate;
      overflow: hidden;
      border: 0.1rem solid rgb(var(--blue));
      background-color: rgb(var(--white));
      padding: 0.75rem;
      min-height: 10rem;
      font-size: var(--regular-size);
    }

    &--vertical-align {
      vertical-align: middle;
    }

    &--competence {
      text-align: left;
      padding: 1rem 1.5rem 1.2rem 1.2rem !important;
      max-width: 20rem;
      overflow-wrap: break-word;
      text-align: center;
      vertical-align: middle;
    }

  }


  &__competence{

    background-color: rgb(var(--white));

    &--high{
      background-color: rgba(var(--light-green), 0.5) !important;
    }
    &--medium{
      background-color: rgba(var(--yellow), 0.5) !important;
    }
    &--low{
      background-color: rgba(var(--light-pink), 0.5) !important;
    }
    &--null{ 
      background-color: rgba(var(--pink), 0.5) !important;
    }
  }

  &__header{
    &--high{
      background-color: rgb(var(--light-green)) !important;
    }
    &--medium{
      background-color: rgb(var(--yellow)) !important;
    }
    &--low{
      background-color: rgb(var(--light-pink)) !important;
    }
    &--null{ 
      background-color: rgb(var(--pink)) !important;
    }
  }
}

.neuro-table {
  position: relative;
  &__cell--actions {
    display: flex;
    flex-wrap: wrap;
    column-gap: 1rem;
    align-items: center;
    justify-content: center;
  }

  &__cell--action {
    user-select: none;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
      color: rgb(59, 146, 163)
    }
  }

  &__cell--action-icon {
    font-size: 1.5rem
  }
}

.td-ellipsis {
  max-width: 20rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


.table-adaptative {

  & td {
    vertical-align: middle;
  }

  @include tablet-landscape {
    
    width: 100%;
    padding: 0;
    border: 0;
    box-shadow: none;
    
    & thead {
      display: none;
    }

    & tr {
      border-radius: 1rem;
      border: 0.1rem solid rgb(var(--blue));
      display: grid;
      justify-content: center;
      margin-bottom: 10px;
      padding: 1rem 0;
    }

    & td {
      align-items: center;
      display: flex !important;
      font-size: 13px;
      padding: 1rem;
      text-align: right;
      width: 100%;
      & div,span {
        justify-content: start !important;
      }
      &.td-ellipsis {
        max-width: none;
        span {                
          text-align: left;
          max-width: 12rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    & td:last-child {
      border-bottom: 0 !important;
    }

    & td:before {
      color: rgb(var(--blue));
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-align: start;
      text-transform: uppercase;
      width: 13.4rem;
      margin-right: 1rem;
    }
    
  }
}

.table-thumbnail {
  width: 4.8rem;
  height: 4.8rem;
  min-width: 4.8rem;
  min-height: 4.8rem;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle;
  margin-right: 1rem;
}