.house-icon {
  --house-bg-color: #1266d8;
  
  align-items: center;
  background-color: var(--house-bg-color);
  background-image: url(/assets/images/neuromindset/icons/house.png);
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 100%;
  display: flex;
  height: 40px;
  justify-content: center;
  justify-items: center;
  width: 40px;
  cursor: pointer;
  transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);

  &:hover {
    transform: scale(1.05);
  }

  &--bg-blue {
    --house-bg-color: #1266d8;
  }

  &--bg-yellow {
    --house-bg-color: #1266d8;
  }

  &--bg-orange {
    --house-bg-color: #2d1d54;
  }

  &--bg-green {
    --house-bg-color: #1266d8;
  }
}
