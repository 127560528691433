.lab-selector {

  display: flex;
  column-gap: 2rem;
  cursor: pointer;

  &--item {
    display: flex;
    align-items: center;
    transition: border-bottom 0.3s ease;
    border-bottom: 3px solid transparent;
   
  }

}
    