.spinner {
  position: absolute;
  width: 100%;
  height: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  
  &--show {
    display: flex;
  }

  &--fixed{
    height: 100vh;
    width: 100vw;
  }

  &__content{
    margin: 0 auto;
    position: relative;
    width: 10rem;
    &::before {
      display: block;
      padding-top: 100%;
    }
  }
  
  &__circular {
    animation: rotate 2s linear infinite;
    bottom: 0;
    height: 5rem;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    transform-origin: center center;
    width: 5rem;
  }

  &__path {
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    stroke-linecap: round;
  }

  &__lg{
   width: 5rem;
   height: 5rem; 
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -3.5rem;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -12.4rem;
  }
}

@keyframes color {
  100%,
  0% {
    stroke: rgb(var(--red));
  }
  40% {
    stroke: rgb(var(--blue)) ;
  }
  66% {
    stroke: rgb(var(--dark-green));
  }
  80%,
  90% {
    stroke: rgb(var(--orange));
  }
}
